import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  inject,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BottomNavbarComponent, TitileNavbarComponent } from '@ui-components';
import { CustomNotificationQueryHandler } from '../../application/handlers';
import { CustomNotificationDTO } from '../../application/interfaces';
import { Observable, tap } from 'rxjs';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FullHeightScrollableContainerDirective } from '@core';
import { RouterLink } from '@angular/router';
import { CustomNotificationContentComponent } from './custom-notification-content/custom-notification-content.component';

@Component({
  selector: 'lib-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    AsyncPipe,
    NgIf,
    BottomNavbarComponent,
    NgForOf,
    RouterLink,
    FullHeightScrollableContainerDirective
  ],
  providers: [CustomNotificationQueryHandler]
})
export class CustomNotificationComponent {
  @ViewChild('dynamicContainer', { read: ViewContainerRef, static: true })
  dynamicContainer!: ViewContainerRef;

  private readonly customNotificationQueryHandler: CustomNotificationQueryHandler =
    inject(CustomNotificationQueryHandler);
  private readonly componentFactoryResolver: ComponentFactoryResolver = inject(ComponentFactoryResolver);

  readonly data$: Observable<CustomNotificationDTO> = this.customNotificationQueryHandler.getData();

  constructor() {
    this.customNotificationQueryHandler
      .getData()
      .pipe(tap((data: CustomNotificationDTO) => this.loadDynamicComponent(data.html)))
      .subscribe();
  }

  private loadDynamicComponent(html: string): void {
    this.dynamicContainer.clear();

    const factory: ComponentFactory<CustomNotificationContentComponent> =
      this.componentFactoryResolver.resolveComponentFactory(CustomNotificationContentComponent);
    const componentRef: ComponentRef<CustomNotificationContentComponent> =
      this.dynamicContainer.createComponent(factory);

    componentRef.instance.htmlContent = html;
  }
}
