import { ChangeDetectionStrategy, Component, inject, QueryList, ViewChildren } from '@angular/core';
import { BottomNavbarComponent, HomeNavbarComponent } from '@ui-components';
import { map, Observable } from 'rxjs';
import { NotificationsListQueryHandler, NotificationViewModel } from '@notifications';
import { AsyncPipe, NgIf } from '@angular/common';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '@hosted-games';
import { Router } from '@angular/router';
import { DashboardNavbarComponent } from '../dashboard-navbar/dashboard-navbar.component';
import { FullHeightScrollableContainerDirective } from '@core';
import { DashboardGamesListComponent } from '../dashboard-games-list/dashboard-games-list.component';
import { DashboardInvitationsComponent } from '../dashboard-invitations/dashboard-invitations.component';
import { DashboardEmptyStateComponent } from '../dashboard-empty-state/dashboard-empty-state.component';
import { DashboardTeamsComponent } from '../dashboard-teams/dashboard-teams.component';

@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HomeNavbarComponent,
    AsyncPipe,
    BottomNavbarComponent,
    DashboardNavbarComponent,
    DashboardGamesListComponent,
    NgIf,
    DashboardInvitationsComponent,
    DashboardEmptyStateComponent,
    DashboardTeamsComponent,
    FullHeightScrollableContainerDirective
  ],
  providers: [NotificationsListQueryHandler]
})
export class DashboardComponent {
  @ViewChildren(DashboardGamesListComponent)
  dashboardGamesListComponent!: QueryList<DashboardGamesListComponent>;

  private readonly notificationsListQueryHandler: NotificationsListQueryHandler = inject(NotificationsListQueryHandler);
  private readonly router: Router = inject(Router);

  public isGameAvailable: boolean = true;
  public isInvitationsAvailable: boolean = true;
  public isTeamsAvailable: boolean = true;
  public canCreateTeam: boolean = false;

  get isEmptyStateVisible(): boolean {
    return !this.isGameAvailable && !this.isInvitationsAvailable && !this.isTeamsAvailable;
  }

  readonly isNewNotifications$: Observable<boolean> = this.notificationsListQueryHandler.getNotificationsList().pipe(
    map((notifications: NotificationViewModel[]) =>
      notifications.filter((notification: NotificationViewModel) => !notification.isRead)
    ),
    map((notifications: NotificationViewModel[]) => !!notifications?.length)
  );

  goToGameCreator(): void {
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`);
  }

  setIsInvitationsAvailable(event: boolean): void {
    this.isInvitationsAvailable = event;
  }

  setIsGameAvailable(event: boolean): void {
    this.isGameAvailable = event;
  }

  setIsTeamsAvailable(event: boolean): void {
    this.isTeamsAvailable = event;
  }

  setCanCreateTeam(event: boolean): void {
    this.canCreateTeam = event;
  }

  invitationAccetped(): void {
    this.dashboardGamesListComponent?.forEach((child: DashboardGamesListComponent) => child?.refresh());
  }
}
