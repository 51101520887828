import { inject } from '@angular/core';
import { InvitationsService } from '../../infrastructure/http-service';
import { Observable, switchMap } from 'rxjs';
import { InvitePlayersRequestDTO } from '../interfaces';
import { ActivatedRoute, Params } from '@angular/router';

export class InvitePlayersCommandHandler {
  private readonly invitationsService: InvitationsService = inject(InvitationsService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  sendInvitations(players: InvitePlayersRequestDTO): Observable<void> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.invitationsService.invite(params['id'], players))
    );
  }
}
