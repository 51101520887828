import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CustomNotificationAttribute, CustomNotificationRedirectType } from '../../../application/enums';

// EXAMPLE
//  <button redirect-type="inApp" redirect-url="/find-games"></button>
//  <button redirect-type="web" redirect-url="https://www.google.pl"></button>
//
// both attributes should be into one element

@Component({
  selector: 'lib-custom-notification-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  template: `<div class="content"></div>`
})
export class CustomNotificationContentComponent implements OnInit {
  @Input() htmlContent!: string;

  private renderer: Renderer2 = inject(Renderer2);
  private elementRef: ElementRef = inject(ElementRef);
  private router: Router = inject(Router);

  ngOnInit(): void {
    this.handleRedirect();
  }

  private handleRedirect(): void {
    const container = this.elementRef.nativeElement.querySelector('.content');
    container.innerHTML = this.htmlContent;

    this.renderer.listen(container, 'click', () => {
      const element = container.querySelector(`[${CustomNotificationAttribute.RedirectType}]`);

      const redirectType: CustomNotificationRedirectType = element.getAttribute(
        CustomNotificationAttribute.RedirectType
      );
      const redirectUrl: string = element.getAttribute(CustomNotificationAttribute.RedirectUrl);

      if (redirectType === CustomNotificationRedirectType.InApp) {
        this.router.navigateByUrl(redirectUrl);
      }

      if (redirectType === CustomNotificationRedirectType.Web) {
        window.open(redirectUrl, '_blank');
      }
    });
  }
}
