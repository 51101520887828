<ng-container
  *ngIf="{
    approvedGames: (playerApprovedGames$ | async),
    waitingGames: (playerWaitingGames$ | async),
    hostedGames: (hostedGames$ | async)
  } as games">
  <div
    *ngIf="
      games.approvedGames?.length ||
      games.waitingGames?.length ||
      games.hostedGames?.length
    "
    (click)="changeGamesVisibility()"
    class="or-d-flex or-justify-content-between or-align-items-center or-px-24-px or-mt-20-px">
    <div class="or-head-md">Twoje najbliższe gierki</div>
    <img
      [src]="
        isGamesExpanded
          ? 'assets/icons/chevron-up.svg'
          : 'assets/icons/chevron-down-primary.svg'
      " />
  </div>

  <ng-container *ngIf="isGamesExpanded">
    <ng-container *ngIf="games.hostedGames?.length">
      <div class="or-pl-24-px or-mt-16-px or-mb-12-px or-head-md or-color-secondary-2">Organizator</div>
      <div *ngFor="let game of games.hostedGames" class="or-mb-12-px">
        <lib-game-card-v2
          [game]="game"
          [redirectType]="gameRedirectType.HOSTED"
          [showShareButton]="true"
          [showWaitingPlayers]="true"></lib-game-card-v2>
      </div>
    </ng-container>

    <ng-container *ngIf="games.approvedGames?.length || games.waitingGames?.length">
      <div class="or-pl-24-px or-mt-24-px or-mb-12-px or-head-md or-color-secondary-2">Gracz</div>
      <div *ngFor="let game of games.approvedGames" class="or-mb-12-px">
        <lib-game-card-v2
          [game]="game"
          [redirectType]="gameRedirectType.GAME"
          [showShareButton]="true"
          [showGameParticipationStatus]="true"
          [showHostDetails]="true"></lib-game-card-v2>
      </div>

      <div *ngFor="let game of games.waitingGames" class="or-mb-12-px">
        <lib-game-card-v2
          [game]="game"
          [redirectType]="gameRedirectType.GAME"
          [showShareButton]="true"
          [showGameParticipationStatus]="true"
          [showHostDetails]="true"></lib-game-card-v2>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
