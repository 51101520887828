import { HostedGamesService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HostedGameDTO } from '../interfaces';
import { capitalizeFirstLetter, GameDetailsModel, GameDTO, mapGameDTOToGameDetailsUtil } from '@core';

export class GetHostedPastGamesQueryHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);

  getGames(): Observable<Map<string, GameDetailsModel[]>> {
    return this.hostedGamesService
      .getPastGames()
      .pipe(map((games: HostedGameDTO) => this.groupAndMapGamesByMonth(games.hostedGames)));
  }

  private groupAndMapGamesByMonth(games: GameDTO[]): Map<string, GameDetailsModel[]> {
    return games.reduce((groupedGames, game) => {
      const gameDate: Date = new Date(game.gameDateStart);
      const monthYear: string = capitalizeFirstLetter(
        gameDate.toLocaleString('pl-PL', {
          month: 'long',
          year: 'numeric'
        })
      );

      const updatedGroup = (groupedGames.get(monthYear) || []).concat(mapGameDTOToGameDetailsUtil(game));
      return new Map(groupedGames).set(monthYear, updatedGroup);
    }, new Map<string, GameDetailsModel[]>());
  }
}
