<lib-title-navbar title="Szczegóły gierki" [showDots]="true" (onDotsClicked)="manageGame()"></lib-title-navbar>

<div [fullHeightScrollableContainer]="139">
  <ng-container *ngIf="gameDetails$ | async as game">
    <div class="or-p-16-px">
      <lib-game-details
        [game]="game"
        [showHostInfo]="true"
        [showNavigateLink]="true"
        [showPhoneNumber]="!!(showPhoneNumber$ | async)"
        (onSendMessage)="sendMessage($event)"
        [showGoToChat]="showGoToChat(game.gameParticipationStatus) || game.isHost"
        ><ng-container *ngTemplateOutlet="paymentStatus"></ng-container>
      </lib-game-details>
    </div>

    <ng-container *ngIf="game.players?.length">
      <ng-container
        *ngTemplateOutlet="
          playerList;
          context: { players: game.players, title: 'Lista graczy' }
        "></ng-container>
    </ng-container>

    <ng-container *ngIf="game.playersApplications?.length">
      <ng-container
        *ngTemplateOutlet="
          playerList;
          context: {
            players: game.playersApplications,
            title: 'Oczekujący na akceptację'
          }
        "></ng-container>
    </ng-container>

    <ng-template #paymentStatus>
      <div *ngIf="game.isPaid" class="is-paid border-radius-8 is-paid or-head-sm or-p-4-px">Zapłacone</div>
      <div *ngIf="!game.isPaid" class="is-not-paid border-radius-8 is-paid or-head-sm or-p-4-px">Niezapłacone</div>
    </ng-template>
  </ng-container>
</div>
<lib-bottom-navbar></lib-bottom-navbar>

<ng-template #playerList let-players="players" let-title="title">
  <div class="or-p-16-px">
    <div class="or-head-xs or-color-secondary-2 or-mb-12-px">{{ title }}</div>

    <ng-container *ngFor="let player of players; let i = index">
      <div class="or-d-flex or-justify-content-between or-align-items-center">
        <div class="or-d-flex or-align-items-center" [redirectToUserProfile]="player.userId">
          <lib-or-avatar
            [avatarUrl]="player.userThumbnail"
            [initials]="player.userDisplayName"
            [avatarSize]="'sm'"></lib-or-avatar>

          <div class="or-head-sm text-no-wrap">{{ i + 1 }}.&nbsp;{{ player.userDisplayName }}</div>
          <img
            *ngIf="player.playerPreferences?.preferredPosition === 'GOALKEEPER'"
            class="or-ml-8-px"
            src="assets/icons/goalkeeper.svg" />
        </div>

        <img
          *ngIf="player.userId && !(shouldHideMessageIcon(player.userId) | async)"
          (click)="sendMessage(player.userId)"
          src="assets/icons/message-icon.svg"
          class="or-mr-4-px" />
      </div>

      <div class="or-d-flex or-justify-content-center or-my-4-px">
        <div class="custom-divider-hosted-details or-my-4-px"></div>
      </div>
    </ng-container>
  </div>
</ng-template>
