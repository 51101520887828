import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RankRouting } from './rank.routing';
import { RankService } from './infrastructure';

@NgModule({
  imports: [RouterModule.forChild(RankRouting)],
  providers: [RankService]
})
export class RankModule {}
