import {
  CopyService,
  DeepLinkService,
  DynamicLinkType,
  GameDetailsModel,
  MODAL_TOKEN,
  ModalProvider,
  socialMetaTagInfoMapper,
  USER_DATA_TOKEN,
  UserDataProvider,
  UserModel
} from '@core';
import { inject } from '@angular/core';
import { environment } from 'src/environment/environment';
import { AppRoutes } from 'src/app/app-routes.enum';
import { GamesRoutes } from '../../../../games/src/lib/games-routes.enum';
import { Share } from '@capacitor/share';
import { Observable, switchMap, take, tap } from 'rxjs';
import { shareGameContent } from './social-content';

export class ShareGameViewService {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly deepLinkService: DeepLinkService = inject(DeepLinkService);
  private readonly copyService: CopyService = inject(CopyService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  copyGameLink(gameId: string): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() => this.userDataProvider.userData$),
        switchMap((userData: UserModel) => this.generateGameURL(gameId, userData.userId)),
        take(1),
        switchMap((deeplink: string) => this.copyService.copyToClipboard(deeplink)),
        tap(() => this.modalProvider.dismissLoading$()),
        switchMap(() => this.modalProvider.dismissModal$())
      )
      .subscribe();
  }

  advancedShareNative(game: GameDetailsModel): void {
    this.modalProvider
      .showLoading$()
      .pipe(
        switchMap(() => this.userDataProvider.userData$),
        switchMap((userData: UserModel) => this.generateGameURL(game.gameId, userData.userId)),
        take(1),
        tap((deeplink: string) => {
          return Share.share({
            text: shareGameContent(deeplink, game),
            url: deeplink
          });
        }),
        tap(() => this.modalProvider.dismissLoading$()),
        switchMap(() => this.modalProvider.dismissModal$())
      )
      .subscribe();
  }

  copySocialContent(game: GameDetailsModel): Observable<void> {
    return this.modalProvider.showLoading$().pipe(
      switchMap(() => this.userDataProvider.userData$),
      switchMap((userData: UserModel) => this.generateGameURL(game?.gameId, userData.userId)),
      take(1),
      switchMap((deeplink: string) => this.copyService.copyToClipboard(shareGameContent(deeplink, game))),
      tap(() => this.modalProvider.dismissLoading$()),
      switchMap(() => this.modalProvider.dismissModal$())
    );
  }

  openShareFacebookModal(game: GameDetailsModel, component: any): Observable<void> {
    return this.modalProvider.showModal$({
      component: component,
      componentProps: {
        game: game
      },
      cssClass: 'present-modal'
    });
  }

  openFacebookGroup(game: GameDetailsModel, groupUrl: string): Observable<void> {
    return this.modalProvider.showLoading$().pipe(
      switchMap(() => this.copySocialContent(game)),
      take(1),
      tap(() => window.open(groupUrl, '_blank')),
      tap(() => this.modalProvider.dismissLoading$())
    );
  }

  private generateGameURL(gameId: string, userId: string): Observable<string> {
    const url: string = `${environment.frontendUrl}/${AppRoutes.GAMES}/${GamesRoutes.SHARED}/${gameId}?referrer=${userId}`;
    const socialMetaTagInfo = socialMetaTagInfoMapper[DynamicLinkType.GAME];

    return this.deepLinkService.generateLink(url, socialMetaTagInfo);
  }
}
