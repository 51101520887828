import { HostedGamesService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomPlayerModel } from '../interfaces';

export class EditPlayerCommandHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);

  editPlayer(gameId: string, player: CustomPlayerModel, playerId: string): Observable<void> {
    return this.hostedGamesService.editPlayer(gameId, player, playerId);
  }
}
