import { ChatService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ChannelDetailsQueryParam } from '../enums';
import { ChannelDetailsDTO, ChannelDetailsPayloadDTO } from '../interfaces';

export class ChannelDetailsByReferenceQueryHandler {
  private readonly chatService: ChatService = inject(ChatService);

  details(payload: ChannelDetailsPayloadDTO): Observable<ChannelDetailsDTO> {
    const params = () => {
      if (payload.param === ChannelDetailsQueryParam.TEAM) return `teamId=${payload.teamId}`;
      return `gameId=${payload.gameId}`;
    };

    return this.chatService.channelDetailsByReference(params());
  }
}
