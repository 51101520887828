<ng-container *ngIf="availableGroups$ | async as availableGroups">
  <div class="or-px-12-px or-py-28-px">
    <div class="or-head-lg or-text-align-center or-mb-16-px">Udostępnij gierkę na grupach Facebook</div>
    <ng-container *ngIf="availableGroups?.length; else emptyState">
      <div class="or-text-md or-text-align-center or-mb-16-px">
        Pozwól nam rosnąć - Warto udostępnić gierkę na grupach facebookowych.
      </div>
      <div class="or-head-md or-text-align-center or-mb-16-px">
        W szybszy sposób znajdziesz graczy, a o aplikacji dowie się jeszcze więcej osób!
      </div>
      <div class="or-text-md or-text-align-center or-mb-16-px">
        Treść posta zostanie skopiowana, a Tobie zostanie jedynie dodanie go na wybraną grupkę!
      </div>

      <div class="scrollable-container">
        <div *ngFor="let group of availableGroups">
          <div
            (click)="openGroup(group.fbGroupExternalLink)"
            class="or-d-flex or-bg-white or-p-12-px border-radius-4 or-mb-8-px border-shade">
            <img src="assets/icons/share-shade-2.svg" class="or-mr-8-px or-size-32-px" />
            <div>{{ group.fbGroupName }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <button (click)="close()" class="button-primary-style or-h-48-px or-w-100-pc or-head-md or-mt-12-px">
      Zamknij
    </button>
  </div>

  <ng-template #emptyState>
    <div class="or-text-lg or-text-align-center">
      Niestety, nie znaleźliśmy dostępnych grup w Twojej okolicy. Możesz skopiować treść posta i opublikować na
      wybranych grupach.
    </div></ng-template
  >
</ng-container>
