import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BottomNavbarComponent,
  GameCardV2Component,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent
} from '@ui-components';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../hosted-games.routes.enum';
import { BehaviorSubject, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { GetHostedPastGamesQueryHandler } from '../../application/handlers';
import { Router } from '@angular/router';
import { FullHeightScrollableContainerDirective, GameDetailsModel, MODAL_TOKEN, ModalProvider } from '@core';
import { CreateGameViewService } from '../../application/view-services';

@Component({
  selector: 'lib-past-future-games',
  templateUrl: './hosted-past-games.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BottomNavbarComponent,
    GamesMenuComponent,
    TitileNavbarComponent,
    FullHeightScrollableContainerDirective,
    GameCardV2Component
  ],
  providers: [GetHostedPastGamesQueryHandler]
})
export class HostedPastGamesComponent {
  public redirectType: RedirectTypeEnum = RedirectTypeEnum.HOSTED;

  private readonly getHostedPastGamesQueryHandler: GetHostedPastGamesQueryHandler =
    inject(GetHostedPastGamesQueryHandler);
  private readonly router: Router = inject(Router);
  private readonly createGameViewService: CreateGameViewService = inject(CreateGameViewService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  private showPlaceholderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly games$: Observable<Map<string, GameDetailsModel[]>> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.getHostedPastGamesQueryHandler.getGames()),
    tap((games: Map<string, GameDetailsModel[]>) => this.showPlaceholderSubject.next(games?.size === 0)),
    tap(() => this.isPageLoaded$.next(true)),
    tap(() => this.modalProvider.dismissLoading$()),
    shareReplay(1)
  );

  readonly showPlaceholder$: Observable<boolean> = this.showPlaceholderSubject.asObservable();

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.PAST}`]);
  }

  addSimilarGame(game: GameDetailsModel): void {
    this.createGameViewService.setGame(game);
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`);
  }
}
