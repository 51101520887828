import { NgModule } from '@angular/core';
import { FindGamesRouting } from './find-games.routing';
import { RouterModule } from '@angular/router';
import { NearbyGamesService } from './infrastructure/http-services';

@NgModule({
  imports: [RouterModule.forChild(FindGamesRouting)],
  providers: [NearbyGamesService]
})
export class FindGamesModule {}
