import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, shareReplay, switchMap, tap } from 'rxjs';
import {
  BottomNavbarComponent,
  GameCardV2Component,
  GameDetailsComponent,
  GamesMenuComponent,
  RedirectTypeEnum,
  TitileNavbarComponent
} from '@ui-components';
import { AppRoutes } from 'src/app/app-routes.enum';
import { GamesRoutes } from '../../games-routes.enum';
import { Router } from '@angular/router';
import { FutureGameDTO } from '../../application/interfaces';
import { FullHeightScrollableContainerDirective, GameDetailsModel } from '@core';
import { ChatService, CreateSingleChatCommandHandler } from '@messages';
import { GetFutureGamesQueryHandler } from '../../application/handlers';

@Component({
  selector: 'lib-future-games',
  templateUrl: './future-games.component.html',
  styleUrls: ['./future-games.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TitileNavbarComponent,
    BottomNavbarComponent,
    GamesMenuComponent,
    GameDetailsComponent,
    FullHeightScrollableContainerDirective,
    GameCardV2Component
  ],
  providers: [GetFutureGamesQueryHandler, CreateSingleChatCommandHandler, ChatService]
})
export class FutureGamesComponent {
  protected readonly gameRedirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;

  private readonly gamesTypeSubject: BehaviorSubject<keyof FutureGameDTO> = new BehaviorSubject<keyof FutureGameDTO>(
    'approved'
  );

  private showPlaceholderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isPageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly getFutureGamesQueryHandler: GetFutureGamesQueryHandler = inject(GetFutureGamesQueryHandler);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);
  private readonly router: Router = inject(Router);

  readonly games$: Observable<GameDetailsModel[]> = this.gamesTypeSubject.asObservable().pipe(
    switchMap((type: keyof FutureGameDTO) =>
      type === 'approved' ? this.getFutureGamesQueryHandler.getApproved() : this.getFutureGamesQueryHandler.getWaiting()
    ),
    tap((games: GameDetailsModel[]) => this.showPlaceholderSubject.next(!games.length)),
    tap(() => this.isPageLoaded$.next(true))
  );

  readonly showPlaceholder$: Observable<boolean> = this.showPlaceholderSubject.asObservable().pipe(shareReplay(1));

  get isApprovedTabActive() {
    return this.gamesTypeSubject.getValue() === 'approved';
  }

  changeDataSource(event: keyof FutureGameDTO): void {
    this.gamesTypeSubject.next(event);
  }

  goToFutureGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.FUTURE}`]);
  }

  goToPastGames(): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.PAST}`]);
  }

  sendMessage(userId: string): void {
    this.createSingleChatCommandHandler.create(userId).subscribe();
  }
}
