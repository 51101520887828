import { Observable, switchMap, take } from 'rxjs';
import { inject } from '@angular/core';
import { NotificationsAlertsService } from '../../infrastructure';
import { CoordinatesDTO, GEOLOCATION_TOKEN, GeolocationProvider } from '@core';

export class UpdateAlertsLocationCommandHandler {
  private notificationsAlertsService: NotificationsAlertsService = inject(NotificationsAlertsService);
  private geolocationProvider: GeolocationProvider = inject(GEOLOCATION_TOKEN);

  setLocation(): Observable<void> {
    return this.geolocationProvider.getCoordinates().pipe(
      take(1),
      switchMap((coordinates: CoordinatesDTO | null) => this.notificationsAlertsService.setLocation(coordinates))
    );
  }
}
