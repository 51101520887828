import { Routes } from '@angular/router';
import { RankQaComponent, TotalRankComponent } from './components';
import { RankRoutes } from './rank.routes.enum';

export const RankRouting: Routes = [
  {
    path: RankRoutes.ROOT,
    component: TotalRankComponent
  },
  {
    path: RankRoutes.QA,
    component: RankQaComponent
  }
];
