import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FacebookGroupsModalHttpService } from './facebook-groups-modal.http-service';
import { Observable, of } from 'rxjs';
import { FacebookGroupsModalDTO } from './facebook-groups-modal.dto';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ShareGameViewService } from '../share-game.view-service';
import { CopyService, GameDetailsModel, MODAL_TOKEN, ModalProvider } from '@core';

@Component({
  selector: 'lib-facebook-groups-modal',
  templateUrl: './facebook-groups-modal.component.html',
  styleUrls: ['./facebook-groups-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf],
  providers: [FacebookGroupsModalHttpService, ShareGameViewService, CopyService]
})
export class FacebookGroupsModalComponent implements OnInit {
  private readonly facebookGroupsModalHttpService: FacebookGroupsModalHttpService =
    inject(FacebookGroupsModalHttpService);
  private readonly shareGameViewService: ShareGameViewService = inject(ShareGameViewService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  @Input({ required: true }) game!: GameDetailsModel;

  public availableGroups$: Observable<FacebookGroupsModalDTO[]> = of([]);

  ngOnInit(): void {
    this.availableGroups$ = this.facebookGroupsModalHttpService.getGroups(this.game.gameId);
  }

  openGroup(groupUrl: string): void {
    this.shareGameViewService.openFacebookGroup(this.game, groupUrl).subscribe();
  }

  close(): void {
    this.modalProvider.dismissAllStoredModals();
  }
}
