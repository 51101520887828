import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';
import { BehaviorSubject, map, Observable, shareReplay, take, tap } from 'rxjs';
import { FaqContent, faqData } from './faq-content';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'lib-faq',
  templateUrl: './faq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, AsyncPipe, NgForOf, NgIf]
})
export class FaqComponent {
  private readonly faqContentSubject: BehaviorSubject<FaqContent[]> = new BehaviorSubject<FaqContent[]>(faqData);

  readonly faqContent$: Observable<FaqContent[]> = this.faqContentSubject.asObservable().pipe(shareReplay(1));

  changeVisibility(item: FaqContent): void {
    this.faqContent$
      .pipe(
        take(1),
        map((data: FaqContent[]) =>
          data.map((faqItem: FaqContent) => {
            if (faqItem.id === item.id) return { ...faqItem, isVisible: !faqItem.isVisible };

            return { ...faqItem, isVisible: false };
          })
        ),
        tap((result: FaqContent[]) => this.faqContentSubject.next(result))
      )
      .subscribe();
  }
}
