import { from, Observable, switchMap, take, tap } from 'rxjs';
import { inject, Injector } from '@angular/core';
import { MODAL_TOKEN, ModalProvider, USER_DATA_TOKEN, UserDataProvider } from '@core';
import { setReferrerIdUtil } from './set-referrer-id.util';
import { AuthService } from '../../infrastructure/http-services';

export class AuthWithProviderCommandHandler {
  private readonly authService: AuthService = inject(AuthService);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  private readonly injector: Injector = inject(Injector);

  authWithGoogle(): Observable<void> {
    return this.modalProvider.showLoading$().pipe(
      switchMap(() => from(this.authService.authWithGoogle())),
      tap(() => this.userDataProvider.setUserData()),
      take(1),
      switchMap(() => setReferrerIdUtil(this.injector))
    );
  }

  authWithApple(): Observable<void> {
    return this.modalProvider.showLoading$().pipe(
      switchMap(() => from(this.authService.authWithApple())),
      tap(() => this.userDataProvider.setUserData()),
      take(1),
      switchMap(() => setReferrerIdUtil(this.injector))
    );
  }
}
