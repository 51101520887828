import { GamesService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PastGameDTO } from '../interfaces';
import { capitalizeFirstLetter, GameDetailsModel, GameDTO, mapGameDTOToGameDetailsUtil } from '@core';

export class GetPastGamesQueryHandler {
  private readonly gamesService: GamesService = inject(GamesService);

  getGames(): Observable<Map<string, GameDetailsModel[]>> {
    return this.gamesService
      .getPastGames()
      .pipe(map((games: PastGameDTO) => this.groupAndMapGamesByMonth(games.games)));
  }

  private groupAndMapGamesByMonth(games: GameDTO[]): Map<string, GameDetailsModel[]> {
    return games.reduce((groupedGames, game) => {
      const gameDate: Date = new Date(game.gameDateStart);
      const monthYear: string = capitalizeFirstLetter(
        gameDate.toLocaleString('pl-PL', {
          month: 'long',
          year: 'numeric'
        })
      );

      const mappedGame: GameDetailsModel = mapGameDTOToGameDetailsUtil(game);
      const updatedGroup = (groupedGames.get(monthYear) || []).concat(mappedGame);
      return new Map(groupedGames).set(monthYear, updatedGroup);
    }, new Map<string, GameDetailsModel[]>());
  }
}
