import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { PLATFORM_TOKEN, PlatformProvider } from '../tokens';

@Directive({
  standalone: true,
  selector: '[fullHeightScrollableContainer]'
})
export class FullHeightScrollableContainerDirective implements OnInit {
  @Input('fullHeightScrollableContainer') height!: number;

  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly renderer2: Renderer2 = inject(Renderer2);

  ngOnInit(): void {
    const element: ElementRef = this.elementRef.nativeElement;
    const calculatedHeight: number = this.platformProvider.isiOS ? this.height + 50 : this.height;

    this.renderer2.setStyle(element, 'height', `calc(100vh - ${calculatedHeight}px)`);
    this.renderer2.setStyle(element, 'overflow-y', 'auto');
  }
}
