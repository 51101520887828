import { Routes } from '@angular/router';
import { GamesRoutes } from './games-routes.enum';
import {
  FutureGamesComponent,
  GameFinishedComponent,
  LineupsComponent,
  MyGameDetailsComponent,
  PastGamesComponent,
  SharedGameComponent
} from './components';
import { loadFuturePlayerGamesResolver, sharedGameResolver } from './application/resolvers';
import { isAuthedGuard, referrerResolver } from '@auth';
import { blockWebVersionGuard } from '@core';

export const GamesRouting: Routes = [
  {
    path: GamesRoutes.ROOT,
    redirectTo: GamesRoutes.FUTURE,
    pathMatch: 'full'
  },
  {
    path: `${GamesRoutes.DETAILS}/:id`,
    component: MyGameDetailsComponent,
    canActivate: [blockWebVersionGuard]
  },
  {
    path: GamesRoutes.FUTURE,
    component: FutureGamesComponent,
    resolve: { loadFuturePlayerGamesResolver },
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: GamesRoutes.PAST,
    component: PastGamesComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `${GamesRoutes.SHARED}/:id`,
    component: SharedGameComponent,
    resolve: { sharedGameResolver, referrerResolver }
  },
  {
    path: `${GamesRoutes.GAME_FINISHED}/:id`,
    component: GameFinishedComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  },
  {
    path: `${GamesRoutes.LINEUPS}/:id`,
    component: LineupsComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  }
];
