import { InvitationsService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class DeclineInvitationCommandHandler {
  private readonly invitationsService: InvitationsService = inject(InvitationsService);

  decline(invitaionId: string): Observable<void> {
    return this.invitationsService.decline(invitaionId);
  }
}
