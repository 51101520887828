import { inject } from '@angular/core';
import { ChatService } from '../../infrastructure/http-service';
import { ChannelMembersDTO, ChannelMembersResponseDTO } from '../interfaces';
import { map, Observable } from 'rxjs';

export class ChannelMembersQueryHandler {
  private readonly chatService: ChatService = inject(ChatService);

  getMembers(channelId: string): Observable<ChannelMembersDTO[]> {
    return this.chatService.channelMembers(channelId).pipe(map((data: ChannelMembersResponseDTO) => data.members));
  }
}
