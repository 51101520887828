import { NotificationsService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable, of, take, tap } from 'rxjs';
import { PLATFORM_TOKEN, PlatformProvider } from '@core';

export class UnregisterTokenCommandHandler {
  private readonly notificationsService: NotificationsService = inject(NotificationsService);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);

  unregister(): Observable<void> {
    const token: string = localStorage.getItem('FCM_Device_Token') as string;

    if (this.platformProvider.isNativeRun) {
      return this.notificationsService.unregisterToken(token).pipe(
        take(1),
        tap(() => localStorage.removeItem('FCM_Device_Token'))
      );
    }

    return of(void 0);
  }
}
