<div *ngIf="userRank$ | async as userRank" class="or-bg-white border-radius-4 or-p-12-px card-box-shadow">
  <div class="or-d-flex or-justify-content-between or-align-items-center">
    <div class="or-head-sm">Liczba punktów: {{userRank.points}}</div>
    <div
      (click)="goToRank()"
      class="or-d-flex or-align-items-center or-bg-alertbar or-p-8-px border-radius-4 or-cursor-pointer">
      <div class="or-head-sm or-mr-8-px">Ranking</div>
      <img src="assets/icons/right-arrow-black.svg" />
    </div>
  </div>
  <div class="or-my-16-px">
    <ion-progress-bar
      [value]="calculateProgressBarValue(userRank)"
      class="custom-progress-bar border-radius-20 or-h-8-px"></ion-progress-bar>
  </div>
  <div class="or-d-flex or-justify-content-between or-align-items-center">
    <div class="or-head-sm"></div>
    <div class="or-head-sm or-color-primary or-cursor-pointer">Twoje miejsce: {{userRank.rank}}</div>
  </div>
</div>
