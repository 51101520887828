import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '@hosted-games';
import { Router } from '@angular/router';
import { FindGamesRoutes } from '../../../../../find-games/src/lib/find-games.routes.enum';

@Component({
  selector: 'lib-dashboard-empty-state',
  templateUrl: './dashboard-empty-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class DashboardEmptyStateComponent {
  private readonly router: Router = inject(Router);

  goToGameCreator(): void {
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}`);
  }

  goToMap(): void {
    this.router.navigateByUrl(`${AppRoutes.FIND_GAMES}/${FindGamesRoutes.MAP}`);
  }
}
