import { UserGamesHttpService } from '../../infrastructure';
import { inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { GameDetailsModel, GameDTO, mapGameDTOToGameDetailsUtil } from '@core';

export class GetUserLastGamesQueryHandler {
  private readonly userGamesHttpService: UserGamesHttpService = inject(UserGamesHttpService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getLastGames(): Observable<GameDetailsModel[]> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.userGamesHttpService.getLastGames(params['id'])),
      map((data: GameDTO[]) => data?.map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game)))
    );
  }
}
