import { GameParticipationStatusEnum } from '../enums';

export interface GameParticipationStatusLabel {
  readonly displayName: string;
  readonly bgColor: string;
  readonly textColor: string;
}

export const gameParticipationStatusLabelMapper: Record<GameParticipationStatusEnum, GameParticipationStatusLabel> = {
  [GameParticipationStatusEnum.Applied]: { displayName: 'Oczekująca', bgColor: '#fff9f0', textColor: '#ff9b1e' },
  [GameParticipationStatusEnum.Approved]: { displayName: 'Potwierdzona', bgColor: '#edfbf9', textColor: '#47d6c2' },
  [GameParticipationStatusEnum.Invited]: { displayName: 'Zaproszenie', bgColor: '#edf1fe', textColor: '#423ce8' },
  [GameParticipationStatusEnum.Host]: { displayName: '', bgColor: '', textColor: '' },
  [GameParticipationStatusEnum.Empty]: { displayName: '', bgColor: '', textColor: '' }
};
