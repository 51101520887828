import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';
import { SendApplicationResponseDTO } from '../../application/interfaces';

export class GameApplicationsService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  sendApplication(gameId: string): Observable<SendApplicationResponseDTO> {
    return this.httpClient.post<SendApplicationResponseDTO>(
      `${this.env.apiUrl}/games/${gameId}/player-applications`,
      null
    );
  }

  removeFromGame(gameId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.env.apiUrl}/player-games/${gameId}`);
  }

  removeApplication(gameId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.env.apiUrl}/games/${gameId}/player-applications`);
  }
}
