import { HostedGamesService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class MarkAsPaidCommandHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);

  markAsPaid(gameId: string, playerId: string, isPaid: boolean): Observable<void> {
    if (!isPaid) return this.hostedGamesService.markAsPaid(gameId, playerId);

    return this.hostedGamesService.markAsNotPaid(gameId, playerId);
  }
}
