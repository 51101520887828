import { Observable } from 'rxjs';
import { GameApplicationsService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { SendApplicationResponseDTO } from '../interfaces';

export class SendApplicationCommandHandler {
  private readonly gameApplicationsService: GameApplicationsService = inject(GameApplicationsService);

  sendApplication(gameId: string): Observable<SendApplicationResponseDTO> {
    return this.gameApplicationsService.sendApplication(gameId);
  }
}
