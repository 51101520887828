import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitileNavbarComponent } from '../navbar';

@Component({
  selector: 'lib-policy-privacy',
  templateUrl: './policy-privacy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent]
})
export class PolicyPrivacyComponent {
  getTermsOfService(): void {
    window.open(
      'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/docs%2FOrlikfy%20Regulamin.pdf?alt=media&token=337ce6fa-1b40-4ed9-a89d-4a917d332e91'
    );
  }

  getPolicyPrivacy(): void {
    window.open(
      'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/docs%2FOrlikfy%20Polityka%20Prywatnosci.pdf?alt=media&token=ad93ba59-a2ef-445e-b4d2-5d75da613b9f'
    );
  }
}
