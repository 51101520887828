import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { OrAvatarComponent } from '@ui-components';
import { switchMap, take, tap } from 'rxjs';
import { AddCustomPlayerComponent } from '../add-custom-player/add-custom-player.component';
import { ActionModalComponent, ButtonLabels, GameDetailsModel, MODAL_TOKEN, ModalProvider } from '@core';
import { AppRoutes } from 'src/app/app-routes.enum';
import { Router } from '@angular/router';
import { RemoveGameCommandHandler, UpdateGameCommandHandler } from '../../application/handlers';
import { HostedGamesService } from '../../infrastructure/http-service';
import { HostedGamesRoutes } from '../../hosted-games.routes.enum';
import { VisibilityEnum } from '../../application/enums';
import { CreateGameViewService } from '../../application/view-services';
import { SendApplicationDirective } from '../../../../../games/src/lib/application/directives';
import { GameApplicationsService } from '../../../../../games/src/lib/infrastructure/http-service';
import { SendApplicationCommandHandler } from '../../../../../games/src/lib/application/handlers';
import { GamesRoutes } from '../../../../../games/src/lib/games-routes.enum';

@Component({
  selector: 'lib-manage-hosted-game',
  templateUrl: './manage-hosted-game.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, SendApplicationDirective, AsyncPipe, NgClass],
  providers: [
    RemoveGameCommandHandler,
    HostedGamesService,
    UpdateGameCommandHandler,
    SendApplicationCommandHandler,
    GameApplicationsService
  ]
})
export class ManageHostedGameComponent {
  @Input() game!: GameDetailsModel;
  @Input() hostId!: string;

  private readonly removeGameCommandHandler: RemoveGameCommandHandler = inject(RemoveGameCommandHandler);
  private readonly updateGameCommandHandler: UpdateGameCommandHandler = inject(UpdateGameCommandHandler);
  private readonly router: Router = inject(Router);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly createGameViewService: CreateGameViewService = inject(CreateGameViewService);

  get visibilityLabel(): ButtonLabels {
    const isPrivate: boolean = this.game?.visibility === VisibilityEnum.PRIVATE;
    const header: string = isPrivate ? 'Zmień gierkę na publiczną' : 'Zmień gierkę na prywatną';

    const text: string = isPrivate
      ? 'Twoja gierka zostanie dodana na mapę gier'
      : 'Twoja gierka zostanie ukryta na mapie gier';
    return { header, text };
  }

  get isPlayerInGame(): boolean | undefined {
    return this.game?.players?.some((player) => player.userId === this.hostId);
  }

  addPlayer(): void {
    this.modalProvider
      .showModal$({
        component: AddCustomPlayerComponent,
        cssClass: 'present-modal',
        componentProps: {
          gameId: this.game.gameId
        }
      })
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }

  removeGame(): void {
    const shortMessage: string = 'Czy na pewno chcesz usunąć organizowaną gierkę?';
    const longMessage: string =
      'Czy na pewno chcesz usunąć organizowaną gierkę? Gracze zapisani do gry dostaną powiadomienie o usunięciu gry';

    this.modalProvider.dismissAllStoredModals();

    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: 'Usuń organizowaną gierkę',
        message: this.game.players?.length ? longMessage : shortMessage,
        btnOk: 'Tak, usuń gierkę',
        btnCancel: 'Anuluj',
        action: () =>
          this.removeGameCommandHandler
            .removeGame(this.game.gameId)
            .pipe(
              take(1),
              switchMap(() => this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}`]))
            )
            .subscribe(() => this.modalProvider.dismissAllStoredModals())
      }
    });
  }

  editGame(): void {
    this.createGameViewService.setGame(this.game);
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.CREATE_GAME}?mode=edit`);
  }

  changeVisibility(): void {
    this.updateGameCommandHandler
      .updateGame(this.game.gameId, {
        visibility: this.game.visibility === VisibilityEnum.PUBLIC ? VisibilityEnum.PRIVATE : VisibilityEnum.PUBLIC
      })
      .pipe(
        take(1),
        tap(() => this.modalProvider.dismissAllStoredModals()),
        switchMap(() =>
          this.modalProvider.showToast$({
            message: 'Zmieniono widoczność gierki',
            position: 'bottom',
            duration: 2000
          })
        )
      )
      .subscribe();
  }

  goToInvitations(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigate([
      `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}/${HostedGamesRoutes.INVITE}`
    ]);
  }

  goToLineups(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.LINEUPS}/${this.game.gameId}`]);
  }
}
