import { Observable } from 'rxjs';
import { GameApplicationsService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { GameParticipationStatusEnum } from '@core';

export class RemoveApplicationCommandHandler {
  private readonly gameApplicationsService: GameApplicationsService = inject(GameApplicationsService);

  remove(gameId: string, gameStatus: GameParticipationStatusEnum): Observable<void> {
    return gameStatus === GameParticipationStatusEnum.Approved
      ? this.gameApplicationsService.removeFromGame(gameId)
      : this.gameApplicationsService.removeApplication(gameId);
  }
}
