import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { FullHeightScrollableContainerDirective } from '@core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { TeamsRoutes } from '../../teams-routes.enum';
import { CreateTeamCommandHandler } from '../../application/handlers';
import { switchMap } from 'rxjs';

@Component({
  selector: 'lib-create-team',
  templateUrl: './create-team.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitileNavbarComponent,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    NgClass,
    AsyncPipe,
    FullHeightScrollableContainerDirective
  ],
  providers: [CreateTeamCommandHandler]
})
export class CreateTeamComponent {
  private readonly createTeamCommandHandler: CreateTeamCommandHandler = inject(CreateTeamCommandHandler);
  private readonly router: Router = inject(Router);

  readonly createTeamForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(25)])
  });

  isFieldInvalid(fieldName: string): boolean {
    const control = this.createTeamForm.get(fieldName);
    return control ? control.touched && control.invalid : false;
  }

  get isNameOverAvailableLength() {
    return this.createTeamForm.get('name')?.value?.length > 25;
  }

  createTeam(): void {
    const teamName: string = this.createTeamForm.get('name')?.value;

    this.createTeamCommandHandler
      .create({ teamName: teamName })
      .pipe(
        switchMap((data) =>
          this.router.navigateByUrl(
            `${AppRoutes.TEAMS}/${data.teamId}/${data.invitationToken}/${TeamsRoutes.CREATE_FINISH}`
          )
        )
      )
      .subscribe();
  }
}
