import { LocationTypeEnum } from '../enums';

export interface LocationTypeModel {
  readonly name: string;
  readonly bgColor: string;
  readonly textColor: string;
  readonly icon: string;
}

export const locationTypeMapper: Record<LocationTypeEnum, LocationTypeModel> = {
  [LocationTypeEnum.OpenField]: {
    name: 'Orlik',
    bgColor: '#EFEBFE',
    textColor: '#5A31F4',
    icon: 'assets/icons/location-type/open-field.svg'
  },
  [LocationTypeEnum.InDoor]: {
    name: 'Hala',
    bgColor: '#e7e5ed',
    textColor: '#342375',
    icon: 'assets/icons/location-type/in-door.svg'
  },
  [LocationTypeEnum.Dome]: {
    name: 'Balon ',
    bgColor: '#e4e3e8',
    textColor: '#13083B',
    icon: 'assets/icons/location-type/dome.svg'
  }
};
