import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { CopyService, CopyValueToClipboardDirective, GameDetailsModel } from '@core';
import { ShareGameViewService } from './share-game.view-service';
import { FacebookGroupsModalComponent } from './facebook-groups-modal/facebook-groups-modal.component';

@Component({
  selector: 'lib-share-game',
  templateUrl: './share-game.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CopyValueToClipboardDirective, AsyncPipe],
  providers: [ShareGameViewService, CopyService]
})
export class ShareGameComponent {
  @Input({ required: true }) game!: GameDetailsModel;

  private readonly shareGameViewService: ShareGameViewService = inject(ShareGameViewService);

  gameLink(): void {
    this.shareGameViewService.copyGameLink(this.game?.gameId);
  }

  copySocialContent(): void {
    this.shareGameViewService.copySocialContent(this.game).subscribe();
  }

  openAdvancedSharing(): void {
    this.shareGameViewService.advancedShareNative(this.game);
  }

  openAvailableFacebookGroups(): void {
    this.shareGameViewService.openShareFacebookModal(this.game, FacebookGroupsModalComponent).subscribe();
  }
}
