import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthHandlersModule } from './application/handlers/auth.handlers.module';
import { AuthService } from './infrastructure/http-services/auth.service';

@NgModule({
  imports: [AuthRoutingModule, AuthHandlersModule],
  providers: [AuthService]
})
export class AuthModule {}
