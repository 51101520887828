import { combineLatest, filter, map, Observable, of, switchMap, take } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { IsAuthedService, USER_DATA_TOKEN, UserDataProvider, UserModel } from '@core';
import { IntroductionEnum } from '../enums';
import { AppRoutes } from 'src/app/app-routes.enum';
import { IntroductionService } from '../../infrastructure';

export const IntroductionGuard = (): Observable<UrlTree | boolean> => {
  const introductionService: IntroductionService = inject(IntroductionService);

  const isAuthed: IsAuthedService = inject(IsAuthedService);
  const userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);
  const router: Router = inject(Router);

  const redirect = (path: IntroductionEnum): UrlTree => {
    const routeParts: string[] = [AppRoutes.INTRODUCTION, path];

    return router.createUrlTree(routeParts);
  };

  return isAuthed.isAuthed$.pipe(
    take(1),
    switchMap((isAuthed: boolean) => {
      if (!isAuthed) {
        return of(true);
      }

      return userDataProvider.userData$.pipe(
        filter((v: UserModel) => !!v.userId),
        switchMap((userData: UserModel) =>
          combineLatest([
            introductionService.isViewed(IntroductionEnum.INTRO_1, userData.userId),
            introductionService.isViewed(IntroductionEnum.INTRO_2, userData.userId),
            introductionService.isViewed(IntroductionEnum.INTRO_3, userData.userId),
            introductionService.isViewed(IntroductionEnum.INTRO_TEAMS, userData.userId),
            introductionService.isViewed(IntroductionEnum.INTRO_4, userData.userId)
          ])
        ),
        map(([isViewed1, isViewed2, isViewed3, isViewedTeamsChat, isViewed4]) => {
          if (!isViewed1) return redirect(IntroductionEnum.INTRO_1);
          if (!isViewed2) return redirect(IntroductionEnum.INTRO_2);
          if (!isViewed3) return redirect(IntroductionEnum.INTRO_3);
          if (!isViewedTeamsChat) return redirect(IntroductionEnum.INTRO_TEAMS);
          if (!isViewed4) return redirect(IntroductionEnum.INTRO_4);

          return true;
        })
      );
    })
  );
};
