<div
  (click)="goToDetails()"
  class="border-radius-8 or-bg-white or-cursor-pointer or-mx-16-px or-pb-12-px or-px-12-px z-index-1 card-box-shadow or-pt-4-px">
  <div class="or-d-flex or-justify-content-between or-align-items-center or-mt-8-px">
    <div class="or-head-lg">{{ game.gameName }}</div>

    <div *ngIf="showShareButton" class="or-d-flex">
      <img src="assets/icons/share-icon.svg" [shareGame]="game" class="or-cursor-pointer" />
    </div>

    <div *ngIf="showAddSimilarGame" class="or-d-flex">
      <div
        class="button-primary-style or-align-items-center or-d-flex or-h-32-px or-head-xs or-px-8-px"
        (click)="similarGameClicked($event)">
        Dodaj ponownie
      </div>
    </div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-8-px">
    <img src="assets/icons/clock-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md">{{ game.formattedDate }}</div>
  </div>

  <div class="or-d-flex or-align-items-center or-mt-8-px">
    <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />
    <div class="or-text-md">{{ game.location.address }}, {{ game.location.city }}</div>
  </div>

  <div *ngIf="showHostDetails" class="or-mt-16-px">
    <div class="or-d-flex or-justify-content-between or-align-items-center">
      <div class="or-ml-2-px or-d-flex or-align-items-center">
        <lib-or-avatar
          [avatarUrl]="game.host?.thumbnail"
          [avatarSize]="'xs'"
          [initials]="game.host?.displayName"></lib-or-avatar>
        <div class="or-head-sm">{{ game.host?.displayName }}</div>
        <ng-container *ngIf="hostRating()">
          <img class="or-ml-8-px or-mr-4-px or-size-16-px or-mb-4-px" src="assets/icons/star-fill.svg" />
          <div class="or-head-xs">{{ hostRating() }}</div>
        </ng-container>
      </div>
      <div>
        <button
          *ngIf="showPhoneNumber"
          [copyToClipboard]="game.gameContactPhone"
          class="button-primary-style or-align-items-center or-d-flex or-head-md or-justify-content-between or-px-8-px or-h-32-px or-cursor-pointer"
          [class.disabled-button]="!game.gameContactPhone">
          <img src="assets/icons/phone-white.svg" class="or-mr-4-px" />
          <span class="or-color-white or-head-xs text-no-wrap" [class.disabled-button]="!game.gameContactPhone"
            >Skopiuj numer
          </span>
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showBottomDetails">
    <div class="custom-divider or-w-100-pc or-my-12-px"></div>

    <div class="or-d-flex or-justify-content-between or-align-items-center">
      <div class="or-d-flex or-align-items-center">
        <div class="or-d-flex or-align-items-center">
          <img src="assets/icons/price-icon.svg" class="or-mr-4-px" />
          <div class="or-text-md">{{ game.priceAmount || 0 ? game.priceAmount + ' zł' : 'Darmowa' }}</div>
        </div>
        <div class="or-ml-8-px">
          <div class="or-d-flex or-align-items-center">
            <img src="assets/icons/member-icon.svg" class="or-mr-4-px" />
            <div class="or-text-md">
              {{ game.occupiedSlots }}/{{ game.totalSlots }}
              <span *ngIf="isAdmin && game.playersApplications?.length">({{ game.playersApplications?.length }})</span>
            </div>
          </div>
        </div>
      </div>

      <div class="or-d-flex or-align-items-center">
        <div
          class="or-d-flex or-align-items-center or-px-8-px or-py-4-px border-radius-8"
          [style.background-color]="locationTypeMapper[game.locationType].bgColor">
          <img [src]="locationTypeMapper[game.locationType].icon" />
          <div class="or-ml-4-px or-text-md" [style.color]="locationTypeMapper[game.locationType].textColor">
            {{locationTypeMapper[game.locationType].name}}
          </div>
        </div>

        <div>
          <div
            class="or-px-8-px or-py-4-px border-radius-8 or-text-md or-ml-8-px"
            [ngClass]="game.isRecurring ? 'recurring-game' : 'single-game'">
            {{game.isRecurring ? 'Powtarzalna' : 'Pojedyncza'}}
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="(showWaitingPlayers && game?.playersApplications?.length) || (showGameParticipationStatus && isParticipationStatusDisplayed(game.gameParticipationStatus))"
      class="or-d-flex or-align-items-center or-justify-content-between or-mt-8-px">
      <div></div>
      <div
        *ngIf="showWaitingPlayers && game?.playersApplications?.length"
        class="or-px-8-px or-py-4-px border-radius-8 or-text-md waiting-players">
        Ilość oczekujących graczy: {{ game.playersApplications?.length }}
      </div>

      <div
        *ngIf="showGameParticipationStatus && isParticipationStatusDisplayed(game.gameParticipationStatus)"
        class="or-px-8-px or-py-4-px border-radius-8 or-text-md"
        [style.background-color]="gameParticipationStatusLabelMapper[game.gameParticipationStatus]?.bgColor"
        [style.color]="gameParticipationStatusLabelMapper[game.gameParticipationStatus]?.textColor">
        {{gameParticipationStatusLabelMapper[game.gameParticipationStatus]?.displayName}}
      </div>
    </div>
    <div></div>
  </ng-container>
</div>
