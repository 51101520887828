import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { NotificationResponseDTO } from '../../application/interfaces';

export class NotificationsService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  registerToken(token: string): Observable<void> {
    return this.httpClient.put<void>(`${this.env.apiUrl}/user-notifications/config/register-device-token`, {
      token: token
    });
  }

  unregisterToken(token: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.env.apiUrl}/user-notifications/config/unregister-device-token/${token}`
    );
  }

  notificationsList(): Observable<NotificationResponseDTO> {
    return this.httpClient.get<NotificationResponseDTO>(`${this.env.apiUrl}/user-notifications`);
  }

  markAsRead(ids: string[]): Observable<void> {
    return this.httpClient.patch<void>(`${this.env.apiUrl}/user-notifications`, { notificationIds: ids });
  }

  markAsClicked(notificationId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.env.apiUrl}/user-notifications/${notificationId}/mark-as-clicked`, null);
  }
}
