import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  CopyService,
  CopyValueToClipboardDirective,
  GameDetailsModel,
  GameParticipationStatusEnum,
  gameParticipationStatusLabelMapper,
  locationTypeMapper
} from '@core';
import { Router } from '@angular/router';
import { RedirectTypeEnum } from '../enums/redirect-type.enum';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../../../../hosted-games/src/lib/hosted-games.routes.enum';
import { GamesRoutes } from '../../../../../games/src/lib/games-routes.enum';
import { NgClass, NgIf } from '@angular/common';
import { OrAvatarComponent } from '../../or-avatar/or-avatar.component';
import { ShareGameDirective } from '../../share-game';

@Component({
  selector: 'lib-game-card-v2',
  templateUrl: './game-card-v2.component.html',
  styleUrls: ['./game-card-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, ShareGameDirective, NgClass, CopyValueToClipboardDirective],
  providers: [CopyService]
})
export class GameCardV2Component {
  @Input({ required: true }) game!: GameDetailsModel;
  @Input() redirectType: RedirectTypeEnum | null = null;
  @Input() showBottomDetails: boolean = true;
  @Input() showShareButton: boolean = false;
  @Input() showAddSimilarGame: boolean = false;
  @Input() showGameParticipationStatus: boolean = false;
  @Input() showWaitingPlayers: boolean = false;
  @Input() showHostDetails: boolean = false;
  @Input() showPhoneNumber: boolean = false;
  @Input() isAdmin: boolean | null = false;

  @Output() onAddSimilarGameClicked: EventEmitter<void> = new EventEmitter<void>();

  private readonly router: Router = inject(Router);

  protected readonly locationTypeMapper = locationTypeMapper;
  protected readonly gameParticipationStatus = GameParticipationStatusEnum;
  protected readonly gameParticipationStatusLabelMapper = gameParticipationStatusLabelMapper;

  goToDetails(): void {
    if (!this.redirectType) return;

    if (this.redirectType === RedirectTypeEnum.GAME) {
      this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${this.game.gameId}`]);
    }

    if (this.redirectType === RedirectTypeEnum.HOSTED) {
      this.router.navigate([`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}`]);
    }
  }

  similarGameClicked(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.onAddSimilarGameClicked.emit();
  }

  hostRating(): string | null {
    if (!this.game.host?.rating.hostRating) return null;
    return this.game.host?.rating.hostRating;
  }

  isParticipationStatusDisplayed(status: GameParticipationStatusEnum): boolean {
    return (
      status === GameParticipationStatusEnum.Applied ||
      status === GameParticipationStatusEnum.Approved ||
      status === GameParticipationStatusEnum.Invited
    );
  }
}
