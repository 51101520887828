import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { map, Observable } from 'rxjs';
import { FacebookGroupsModalDTO } from './facebook-groups-modal.dto';

export class FacebookGroupsModalHttpService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getGroups(gameId: string): Observable<FacebookGroupsModalDTO[]> {
    return this.httpClient
      .get<{ fbGroups: FacebookGroupsModalDTO[] }>(`${this.env.apiUrl}/game/${gameId}/fb-groups`)
      .pipe(map((response) => response.fbGroups));
  }
}
