import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { OrAvatarComponent } from '@ui-components';
import {
  ActionModalComponent,
  ButtonLabels,
  GamePlayersDTO,
  MODAL_TOKEN,
  ModalProvider,
  PresentModalComponent
} from '@core';
import { MarkAsPaidCommandHandler, RemovePlayerCommandHandler } from '../../application/handlers';
import { HostedGamesService } from '../../infrastructure/http-service';
import { Observable, take } from 'rxjs';
import { PaymentConfirmCheckboxComponent } from '../payment-confirm-checkbox/payment-confirm-checkbox.component';
import { HIDE_PAYMENTS_MODAL } from '../../application/utils';
import { AddCustomPlayerComponent } from '../add-custom-player/add-custom-player.component';
import { AddCardModalComponent, AddCommentModalComponent, cardReasonMapper } from '@fair-play';

@Component({
  selector: 'lib-manage-player',
  templateUrl: './manage-player.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, OrAvatarComponent, AsyncPipe],
  providers: [RemovePlayerCommandHandler, MarkAsPaidCommandHandler, HostedGamesService]
})
export class ManagePlayerComponent {
  @Input() player!: GamePlayersDTO;
  @Input() gameId!: string;
  @Input() isPlayerInGame: boolean = true;

  private readonly removePlayerCommandHandler: RemovePlayerCommandHandler = inject(RemovePlayerCommandHandler);
  private readonly markAsPaidCommandHandler: MarkAsPaidCommandHandler = inject(MarkAsPaidCommandHandler);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly cardReasonMapper = cardReasonMapper;

  get paymentStatusText(): ButtonLabels {
    const header: string = this.player.isPaid ? 'Oznacz jako niezapłacone' : 'Oznacz jako zapłacone';

    const text: string = this.player.isPaid
      ? 'Czy na pewno chcesz oznaczyć jako niezapłacone?'
      : 'Czy na pewno chcesz oznaczyć jako zapłacone?';
    return { header, text };
  }

  removePlayer(isPaid: boolean): Observable<void> {
    if (isPaid)
      return this.modalProvider.showModal$({
        component: PresentModalComponent,
        cssClass: 'present-modal',
        componentProps: {
          header: 'Usunięcie gracza',
          message: 'Nie możesz usunąć gracza, który już zapłacił',
          btnTxt: 'Zamknij'
        }
      });

    return this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: 'Usuń gracza z gierki',
        message: 'Czy na pewno chcesz usunąć gracza z gierki?',
        btnOk: 'Tak, usuń gracza',
        btnCancel: 'Anuluj',
        action: () => {
          this.removePlayerCommandHandler.remove(this.gameId, this.player.playerId).pipe(take(1)).subscribe();
        }
      }
    });
  }

  changePaymentStatus(): void {
    const hideModal: { isHide: boolean } = JSON.parse(localStorage.getItem(HIDE_PAYMENTS_MODAL) as string);

    if (hideModal?.isHide) {
      this.modalProvider.dismissAllStoredModals();
      return this.markAsPaid();
    }

    this.modalProvider.showModal$({
      component: ActionModalComponent,
      cssClass: 'present-modal',
      componentProps: {
        header: this.paymentStatusText.header,
        message: this.paymentStatusText.text,
        btnOk: 'Tak, potwierdzam',
        btnCancel: 'Anuluj',
        template: PaymentConfirmCheckboxComponent,
        action: () => this.markAsPaid()
      }
    });
  }

  private markAsPaid(): void {
    this.markAsPaidCommandHandler
      .markAsPaid(this.gameId, this.player.playerId, this.player.isPaid)
      .pipe(take(1))
      .subscribe();
  }

  editPlayer() {
    this.modalProvider
      .showModal$({
        component: AddCustomPlayerComponent,
        cssClass: 'present-modal',
        componentProps: {
          gameId: this.gameId,
          player: this.player,
          isEdit: true
        }
      })
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }

  addCard(): void {
    this.modalProvider
      .showModal$({
        component: AddCardModalComponent,
        cssClass: 'present-modal',
        componentProps: {
          gameId: this.gameId,
          player: this.player
        }
      })
      .pipe(take(1))
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }

  addComment() {
    this.modalProvider
      .showModal$({
        component: AddCommentModalComponent,
        cssClass: 'present-modal',
        componentProps: {
          gameId: this.gameId,
          player: this.player
        }
      })
      .pipe(take(1))
      .subscribe(() => this.modalProvider.dismissAllStoredModals());
  }
}
