<lib-title-navbar title="Polityka prywatnosci"></lib-title-navbar>

<div class="or-d-flex or-justify-content-center or-flex-col or-align-items-center or-mt-32-px">
  <div (click)="getTermsOfService()" class="or-head-md or-text-decoration-underline or-color-primary">
    Pobierz Regulamin
  </div>
  <div (click)="getPolicyPrivacy()" class="or-head-md or-mt-12-px or-text-decoration-underline or-color-primary">
    Pobierz Politykę Prywatności
  </div>
</div>
