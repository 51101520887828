import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { GetUserRankQueryHandler } from '../../application/handlers';
import { AppRoutes } from 'src/app/app-routes.enum';
import { Router } from '@angular/router';
import { UserRankDTO } from '../../application/interfaces';
import { Observable } from 'rxjs';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'lib-player-rank',
  templateUrl: './player-rank.component.html',
  styleUrls: ['./player-rank.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, IonicModule],
  providers: [GetUserRankQueryHandler]
})
export class PlayerRankComponent {
  private readonly getUserRankQueryHandler: GetUserRankQueryHandler = inject(GetUserRankQueryHandler);
  private readonly router: Router = inject(Router);

  readonly userRank$: Observable<UserRankDTO> = this.getUserRankQueryHandler.getUserRank();

  goToRank(): void {
    this.router.navigateByUrl(`${AppRoutes.RANK}`);
  }

  calculateProgressBarValue(userRank: UserRankDTO): number {
    return 1 - (userRank.rank - 1) / (userRank.totalUsers - 1);
  }
}
