import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BehaviorSubject, combineLatestWith, map, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { InvitationsDTO, InvitePlayer } from '../../application/interfaces';
import { InvitationsService } from '../../infrastructure/http-service';
import { GetInvitationsListForHostQueryHandler, InvitePlayersCommandHandler } from '../../application/handlers';
import { OrAvatarComponent, ShareGameDirective } from '@ui-components';
import { IonicModule } from '@ionic/angular';
import {
  FullHeightScrollableContainerDirective,
  GameDetailsModel,
  LocalRefreshService,
  MODAL_TOKEN,
  ModalProvider
} from '@core';
import { ActivatedRoute, Params } from '@angular/router';
import { GamesService } from '@games';
import { GameDetailsQueryHandler } from '../../../../../games/src/lib/application/handlers';

type InvitePlayerViewModel = InvitePlayer & { isSelected: boolean };

type InvitationsViewModel = {
  availableToInvite: InvitePlayerViewModel[];
  invited: InvitePlayer[];
  hidden: InvitePlayer[];
};

@Component({
  selector: 'lib-hosted-invitations-list',
  templateUrl: './hosted-invitations-list.component.html',
  styleUrls: ['./hosted-invitations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgForOf,
    NgIf,
    OrAvatarComponent,
    IonicModule,
    NgClass,
    ShareGameDirective,
    NgTemplateOutlet,
    FullHeightScrollableContainerDirective
  ],
  providers: [
    InvitationsService,
    GetInvitationsListForHostQueryHandler,
    InvitePlayersCommandHandler,
    LocalRefreshService,
    GameDetailsQueryHandler,
    GamesService
  ]
})
export class HostedInvitationsListComponent {
  private readonly invitationsQuery: GetInvitationsListForHostQueryHandler = inject(
    GetInvitationsListForHostQueryHandler
  );
  private readonly invitePlayersCommandHandler: InvitePlayersCommandHandler = inject(InvitePlayersCommandHandler);
  private readonly localRefreshService: LocalRefreshService = inject(LocalRefreshService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly gameDetailsQueryHandler: GameDetailsQueryHandler = inject(GameDetailsQueryHandler);

  private readonly selectedUsers: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public gameDetails$!: Observable<GameDetailsModel>;

  readonly selectedUsers$: Observable<string[]> = this.selectedUsers.asObservable();

  readonly invitations$: Observable<InvitationsViewModel> = this.localRefreshService.refresh$.pipe(
    switchMap(() => this.modalProvider.showLoading$()),
    switchMap(() => this.loadGameDetails()),
    switchMap(() => this.invitationsQuery.getList()),
    combineLatestWith(this.selectedUsers$),
    map(([invitations, selectedUsers]: [InvitationsDTO, string[]]) => {
      return {
        ...invitations,
        availableToInvite: invitations.availableToInvite.map((available) => {
          return {
            ...available,
            isSelected: selectedUsers.includes(available.userId) || available.isFavorite
          };
        })
      };
    }),
    tap(() => this.modalProvider.dismissLoading$()),
    shareReplay(1)
  );

  loadGameDetails(): Observable<GameDetailsModel> {
    return (this.gameDetails$ = this.activatedRoute.params.pipe(
      take(1),
      map((params: Params) => params['id']),
      switchMap((gameId: string) => this.gameDetailsQueryHandler.getGameDetails(gameId))
    ));
  }

  readonly showInviteButton$: Observable<boolean> = this.invitations$.pipe(
    map((invitations: InvitationsViewModel) => !!invitations?.availableToInvite?.length)
  );

  selectPlayer(userId: string): void {
    const selectedUsers: string[] = this.selectedUsers.value;

    if (selectedUsers.includes(userId))
      return this.selectedUsers.next(selectedUsers.filter((id: string) => id !== userId));
    return this.selectedUsers.next([...selectedUsers, userId]);
  }

  selectAll(event: CustomEvent) {
    const eventValue: boolean = event.detail.checked;

    this.invitations$
      .pipe(
        take(1),
        map((invitations: InvitationsViewModel) => {
          const payload: string[] = eventValue
            ? invitations.availableToInvite.map((available: InvitePlayer) => available.userId)
            : [];

          this.selectedUsers.next(payload);
        })
      )
      .subscribe();
  }

  sendInvitations(): void {
    this.selectedUsers$
      .pipe(
        take(1),
        switchMap((selectedUsers: string[]) =>
          this.invitePlayersCommandHandler.sendInvitations({
            userIdsToInvite: selectedUsers,
            userIdsToBeMarkedAsFavoriteForNextInvitations: [],
            userIdsToHideForNextInvitations: []
          })
        )
      )
      .subscribe(() => this.localRefreshService.emit());
  }
}
