<lib-title-navbar title="Gierki pod tym adresem"></lib-title-navbar>

<div *ngIf="locationDetails$ | async as locationDetails" class="or-h-100-pc">
  <div class="or-d-flex or-align-items-center or-mt-16-px or-mx-16-px">
    <img src="assets/icons/map-pin-icon.svg" class="or-mr-8-px" />
    <div>
      <div class="or-head-sm or-color-primary-2">Adres</div>
      <div class="or-head-md">{{ locationDetails.name }} ({{ locationDetails.address }})</div>
    </div>
  </div>

  <div class="or-d-flex or-justify-content-center">
    <div class="custom-divider-location-details or-my-16-px"></div>
  </div>

  <div [fullHeightScrollableContainer]="228" class="disable-scroll">
    <ng-container *ngIf="locationDetails.todayGames.length">
      <div class="or-text-lg or-ml-16-px">Dzisiejsze</div>
    </ng-container>

    <ng-container *ngFor="let game of locationDetails.todayGames">
      <ng-container *ngTemplateOutlet="gameTemplate; context: { $implicit: game }"></ng-container>
    </ng-container>

    <ng-container *ngIf="locationDetails.todayGames.length">
      <div class="or-d-flex or-justify-content-center">
        <div class="custom-divider-location-details or-mb-16-px"></div></div
    ></ng-container>

    <ng-container *ngIf="locationDetails.futureGames.length">
      <div class="or-text-lg or-ml-16-px">Pozostałe</div>
    </ng-container>

    <ng-container *ngFor="let game of locationDetails.futureGames">
      <ng-container *ngTemplateOutlet="gameTemplate; context: { $implicit: game }"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #gameTemplate let-game>
  <div class="or-my-12-px">
    <lib-game-card-v2
      (click)="openDetails(game.gameId); $event.stopPropagation()"
      [game]="game"
      [showGameParticipationStatus]="true"
      [showHostDetails]="true"></lib-game-card-v2>
  </div>
</ng-template>

<lib-bottom-navbar></lib-bottom-navbar>
