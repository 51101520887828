import { Observable, switchMap, take } from 'rxjs';
import { inject } from '@angular/core';
import { FutureGamesStorage } from '../../infrastructure/storage';
import { GamesService } from '../../infrastructure/http-service';
import { FutureGameDTO } from '../interfaces';

export class LoadFutureGamesCommandHandler {
  private readonly futureGamesStorage: FutureGamesStorage = inject(FutureGamesStorage);
  private readonly gamesService: GamesService = inject(GamesService);
  load(): Observable<void> {
    return this.gamesService.getFutureGames().pipe(
      take(1),
      switchMap((data: FutureGameDTO) => this.futureGamesStorage.setContext(data))
    );
  }
}
