import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardRouting } from './dashboard.routing';
import { FutureGamesStorage, GamesService, LoadFutureGamesCommandHandler } from '@games';

@NgModule({
  imports: [RouterModule.forChild(DashboardRouting)],
  providers: [GamesService, FutureGamesStorage, LoadFutureGamesCommandHandler]
})
export class DashboardModule {}
