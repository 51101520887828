export interface FaqContent {
  id: number;
  isVisible: boolean;
  title: string;
  content: string;
  listItems?: string[];
}

export const faqData: FaqContent[] = [
  {
    id: 1,
    isVisible: false,
    title: 'Czym jest Orlikfy?',
    content:
      'Orlikfy jest aplikacją, która ułatwia proces organizowania oraz szukania gierek orlikowych (halowych i balonowych również). Dodatkowo, dzięki Orlikfy prowadzisz swoje statystyki, zbierasz osiągnięcia, komplementy oraz punkty - które w przyszłości będziesz mógł wymieniać na nagrody.'
  },
  {
    id: 2,
    isVisible: false,
    title: 'W jaki sposób mogę dołączyć do gierek?',
    content:
      'Wszystkie gierki znajdziesz w sekcji “Szukaj gierek”. Gierki możesz przeglądać w formie listy oraz mapy. Gdy dołączysz do gierki, pojawi się ona na Twoim ekranie.'
  },
  {
    id: 3,
    isVisible: false,
    title: 'W jaki sposób mogę zorganizować gierkę?',
    content:
      'Gierkę możesz dodać za pomocą fioletowego przycisku “+” w prawym górnym rogu aplikacji. Tworząc gierkę, możesz zdecydować czy jest ona jedynie na pojedyncze szukanie graczy, czy chcesz organizować całość gierki w aplikacji. Wtedy, wybierając “gierkę powtarzalną”, wydarzenie automatycznie tworzy się co tydzień po odbytej gierce.'
  },
  {
    id: 4,
    isVisible: false,
    title: 'Gracz nie przyszedł na gierkę, spóźnił się bądź zachowywał się nie w porządku. Co mogę zrobić?',
    content:
      'Organizator ma możliwość przyznania ostrzeżenia w postaci “Kartki”. Będąc na ekranie danej gierki, przy każdej osobie klikając w 3 kropki po prawej stronie, ma możliwość wystawienia żółtej kartki (w wypadku mniejszego przewinienia) oraz czerwonej w wypadku nieobecności na meczu. Kartki są widoczne w widoku użytkownika.'
  },
  {
    id: 5,
    isVisible: false,
    title: 'Chciałbym nagrodzić gracza za dobrą grę lub za pomoc drużynie. Co mogę zrobić?',
    content:
      'Tak samo jak w przypadku kartek, organizator może przyznać “komplement”. Komplementy również są widoczne w widoku użytkownika. W taki sposób chcemy budować pozytywną kulturę i historię Twoich gierek orlikowych!'
  },
  {
    id: 6,
    isVisible: false,
    title: 'W jaki sposób możesz zdobywać punkty?',
    content: 'Punkty przyznajemy w następujący sposób:',
    listItems: [
      `Stworzenie grupki z powyżej 20 graczami: 5 punktów`,
      `Zorganizowanie gierki: od 3 graczy 1 punkt, od 6 graczy 2 punkty`,
      `Zaproszenie gracza do aplikacji: 1 punkt`,
      `Dołączenie do aplikacji z kodu: 1 punkt`,
      `Zagranie w gierce: 1 punkt`,
      `Zółta kartka: -2 punkty`,
      `Czerwona kartka: -5 punktów`,
      `Komplement: 2 punkty`,
      `Osiągnięcie: 4 punkty`
    ]
  }
];
