import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { map, Observable } from 'rxjs';
import { TopRankDTO, UserRankDTO } from '../../application/interfaces';

export class RankService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  getUserRank(userId: string): Observable<UserRankDTO> {
    return this.httpClient.get<UserRankDTO>(`${this.env.apiUrl}/user/${userId}/activity-rank`);
  }

  getTop50(): Observable<TopRankDTO[]> {
    return this.httpClient
      .get<{ users: TopRankDTO[] }>(`${this.env.apiUrl}/activity-rank/top`)
      .pipe(map((response) => response.users));
  }
}
