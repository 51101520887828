import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GameDetailsModalComponent } from '../../../../../games/src/lib/components/game-details-modal/game-details-modal.component';
import { FullHeightScrollableContainerDirective, MODAL_TOKEN, ModalProvider } from '@core';
import { BottomNavbarComponent, DateFormatPipe, GameCardV2Component, TitileNavbarComponent } from '@ui-components';
import { Observable } from 'rxjs';
import { GetLocationDetailsQueryHandler } from '../../application/handlers';
import { LocationDetailsModel } from '../../application/models';

@Component({
  selector: 'lib-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    DateFormatPipe,
    BottomNavbarComponent,
    TitileNavbarComponent,
    FullHeightScrollableContainerDirective,
    GameCardV2Component
  ],
  providers: [GetLocationDetailsQueryHandler]
})
export class LocationDetailsComponent {
  private readonly modalToken: ModalProvider = inject(MODAL_TOKEN);
  private readonly getLocationDetailsQueryHandler: GetLocationDetailsQueryHandler =
    inject(GetLocationDetailsQueryHandler);

  readonly locationDetails$: Observable<LocationDetailsModel> = this.getLocationDetailsQueryHandler.getDetails();

  openDetails(gameId: string): void {
    this.modalToken.showModal$({
      component: GameDetailsModalComponent,
      componentProps: { gameId: gameId },
      cssClass: 'modal-auto',
      initialBreakpoint: 1,
      breakpoints: [0, 1]
    });
  }
}
