import { CustomNotificationDTO } from '../interfaces';
import { Observable, switchMap } from 'rxjs';
import { CustomNotificationService } from '../../infrastructure';
import { inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

export class CustomNotificationQueryHandler {
  private readonly customNotificationService: CustomNotificationService = inject(CustomNotificationService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getData(): Observable<CustomNotificationDTO> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) => this.customNotificationService.getData(params['id']))
    );
  }
}
