import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { CopyService, GameDetailsModel, MODAL_TOKEN, ModalProvider } from '@core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { HostedGamesRoutes } from '../../../hosted-games.routes.enum';
import { FacebookGroupsModalComponent, ShareGameDirective, ShareGameViewService } from '@ui-components';

@Component({
  templateUrl: './create-game-success-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ShareGameDirective],
  providers: [ShareGameViewService, CopyService]
})
export class CreateGameSuccessModalComponent implements OnInit {
  @Input({ required: true }) game!: GameDetailsModel;
  @Input() isEdit!: boolean;

  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly router: Router = inject(Router);
  private readonly shareGameViewService: ShareGameViewService = inject(ShareGameViewService);

  public header: string = '';
  public message: string = '';

  ngOnInit() {
    this.message = this.isEdit
      ? 'Gratulacje, udało Ci się edytować gierkę!'
      : 'Gratulacje, udało Ci się utworzyć nową gierkę!';

    this.header = this.isEdit ? 'Potwierdzenie edycji gierki' : 'Potwierdzenie dodania nowej gierki';
  }

  goToGame(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(`${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}`);
  }

  goToInvitations(): void {
    this.modalProvider.dismissAllStoredModals();
    this.router.navigateByUrl(
      `${AppRoutes.HOST}/${HostedGamesRoutes.FUTURE}/${this.game.gameId}/${HostedGamesRoutes.INVITE}`
    );
  }

  openFacebookGroups(): void {
    this.goToGame();
    this.shareGameViewService.openShareFacebookModal(this.game, FacebookGroupsModalComponent).subscribe();
  }
}
