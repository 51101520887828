import { map, Observable, switchMap, take } from 'rxjs';
import { inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GameDTO, mapGameDTOToGameDetailsUtil, NerabyGamesDTO } from '@core';
import { NearbyGamesService } from '../../infrastructure/http-services';
import { LocationDetailsModel } from '../models';
import { isSameDay } from 'date-fns';

export class GetLocationDetailsQueryHandler {
  private readonly NearbyGamesService: NearbyGamesService = inject(NearbyGamesService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  getDetails(): Observable<LocationDetailsModel> {
    return this.activatedRoute.params.pipe(
      switchMap((params: Params) =>
        this.NearbyGamesService.getLocationDetails(params['id']).pipe(
          take(1),
          map((data: NerabyGamesDTO) => {
            const today: Date = new Date();

            return {
              locationId: data.locationId,
              name: data.name,
              address: data.address,
              todayGames: data.games
                ?.filter((game: GameDTO) => isSameDay(today, new Date(game.gameDateStart)))
                ?.map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game)),
              futureGames: data.games
                ?.filter(
                  (game: GameDTO) => new Date(game.gameDateStart).setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)
                )
                ?.map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game))
            };
          })
        )
      )
    );
  }
}
