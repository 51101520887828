import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HostedGamesService } from '../../infrastructure/http-service';
import { HostedGameDTO } from '../interfaces';
import { GameDetailsModel, GameDTO, mapGameDTOToGameDetailsUtil } from '@core';

export class GetHostedFutureGamesQueryHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);

  getGames(): Observable<GameDetailsModel[]> {
    return this.hostedGamesService
      .getFutureGames()
      .pipe(map((games: HostedGameDTO) => games.hostedGames.map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game))));
  }
}
