<ion-header [ngClass]="{ 'or-pt-32-px header-bg-color': isIOs() }">
  <ion-toolbar class="header-bg-color or-d-flex or-h-72-px">
    <div class="or-align-items-center or-d-flex or-justify-content-between">
      <div class="or-min-w-32-px">
        <img
          *ngIf="showBackArrow"
          (click)="goBack()"
          src="assets/icons/back-arrow-icon.svg"
          class="or-cursor-pointer" />
      </div>

      <div class="or-color-white or-head-md text-no-wrap">{{ title }}</div>

      <div class="or-align-content-center or-d-flex or-min-w-36-px">
        <img
          *ngIf="showDots"
          (click)="dotsClicked()"
          src="assets/icons/multi-dots-white-icon.svg"
          class="or-px-16-px" />

        <ion-buttons *ngIf="showAddGame || showSettings || showRandomize || showMessageIcon || showRankQAIcon">
          <ion-button *ngIf="showAddGame" (click)="addGame()">
            <ion-icon src="assets/add-button.svg"></ion-icon>
          </ion-button>
          <ion-button *ngIf="showSettings" (click)="settingsClicked()">
            <ion-icon src="assets/icons/settings.svg"></ion-icon>
          </ion-button>
          <ion-button *ngIf="showRandomize" (click)="randomizeClicked()">
            <ion-icon src="assets/icons/randomize-white.svg"></ion-icon>
          </ion-button>
          <ion-button *ngIf="showMessageIcon" (click)="sendMessage()">
            <ion-icon src="assets/icons/message-white.svg"></ion-icon>
          </ion-button>
          <ion-button *ngIf="showRankQAIcon" (click)="showRankQA()">
            <ion-icon src="assets/icons/rank-qa.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
