<div class="or-d-flex or-justify-content-center">
  <div class="or-align-items-center or-d-flex or-flex-col or-w-100-pc or-mr-36-px">
    <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Preferowana pozycja</div>
    <div
      class="card-box-shadow border-radius-4 or-p-8-px or-bg-white or-d-flex or-justify-content-center or-w-100-pc or-text-md"
      [class.or-color-secondary-2]="!playerPreferences.preferredPosition">
      {{ preferredPositionMapper[playerPreferences.preferredPosition] ?? "Nie wybrano" }}
    </div>
  </div>

  <div class="or-align-items-center or-d-flex or-flex-col or-w-100-pc">
    <div class="or-head-xs or-color-secondary-2 or-mb-4-px">Poziom umiejętności</div>
    <div
      class="card-box-shadow border-radius-4 or-p-8-px or-bg-white or-d-flex or-justify-content-center or-w-100-pc or-text-md"
      [class.or-color-secondary-2]="!playerPreferences.preferredLevel">
      {{ gameLevelMapper[playerPreferences.preferredLevel] ?? "Nie wybrano" }}
    </div>
  </div>
</div>
