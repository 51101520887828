import { inject } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { ChatService } from '../../infrastructure/http-service';
import { ChannelTypeEnum } from '../enums';
import { ChatInitializer } from '../initializers';
import { CreateSingleChatResponseDTO } from '../interfaces';

export class CreateSingleChatCommandHandler {
  private readonly chatService: ChatService = inject(ChatService);
  private readonly chatInitializer: ChatInitializer = inject(ChatInitializer);

  create(userId: string): Observable<void> {
    return this.chatInitializer.init(ChannelTypeEnum.SINGLE).pipe(
      take(1),
      switchMap(() => this.chatService.createSingleChat(userId)),
      switchMap((data: CreateSingleChatResponseDTO) =>
        this.chatInitializer.openChannel(data.channelExternalId, ChannelTypeEnum.SINGLE)
      )
    );
  }
}
