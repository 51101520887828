<lib-title-navbar title="Alerty o nowych gierkach"></lib-title-navbar>

<div class="or-mx-12-px or-mt-32-px" [fullHeightScrollableContainer]="170">
  <ng-container *ngIf="notificationsValues$ | async as filterValues">
    <ion-toggle
      justify="space-between"
      [checked]="filterValues.isEnabled"
      class="or-d-flex or-head-xxl"
      (ionChange)="toggleNotifications($event.detail.checked)">
      Alerty</ion-toggle
    >
    <div class="or-note-sm or-color-secondary-2">Ustaw włączone, by dostawać alerty o gierkach w pobliżu</div>

    <div class="custom-divider or-w-90-pc or-my-28-px"></div>

    <div [ngClass]="{ disabled: !filterValues.isEnabled }">
      <span class="or-head-md">Cena</span>
      <div class="or-position-relative or-mt-24-px">
        <ion-range
          [disabled]="!filterValues.isEnabled"
          [dualKnobs]="true"
          [value]="{
            lower: filterValues.price.min,
            upper: filterValues.price.max
          }"
          [min]="0"
          [max]="40"
          (ionInput)="onPriceChange($event)"
          class="or-mx-20-px">
        </ion-range>
        <div class="left-count or-text-md">{{ filterValues.price.min }} PLN</div>
        <div class="right-count or-text-md">{{ filterValues.price.max }} PLN</div>
      </div>

      <div class="or-mt-20-px">
        <span class="or-head-md">Lokalizacja</span>
        <div class="or-position-relative or-mt-24-px">
          <ion-range
            [disabled]="!filterValues.isEnabled"
            [min]="1"
            [max]="71"
            [value]="filterValues.maxDistanceMeters"
            (ionInput)="onDistanceChange($event)"
            class="or-mx-20-px">
          </ion-range>
          <div class="right-count or-text-md">{{ formatDistanceValue(filterValues.maxDistanceMeters) }}</div>
        </div>
      </div>

      <div class="or-mt-20-px">
        <span class="or-head-md">Godzina</span>
        <div class="or-position-relative or-mt-24-px">
          <ion-range
            [disabled]="!filterValues.isEnabled"
            [dualKnobs]="true"
            [value]="{
              lower: filterValues.time.hoursMin,
              upper: filterValues.time.hoursMax
            }"
            [min]="0"
            [max]="24"
            (ionInput)="onTimeChange($event)"
            class="or-mx-20-px">
          </ion-range>
          <div class="left-count or-text-md">{{ filterValues.time.hoursMin }}:00</div>
          <div class="right-count or-text-md">{{ filterValues.time.hoursMax }}:00</div>
        </div>
      </div>

      <div class="or-mt-12-px">
        <span class="or-head-md">Poziom gry</span>
        <div class="or-mt-8-px">
          <ion-chip
            *ngFor="let level of gameLevels"
            class="or-head-xs border-radius-20 or-p-8-px"
            [ngClass]="levelChips(level) ? 'chip-checked' : 'chip-unchecked'"
            (click)="setLevelChips(level)">
            {{ gameLevelMapper[level] }}
          </ion-chip>
        </div>
      </div>

      <div class="or-mt-12-px">
        <span class="or-head-md">Pokazuj gry</span>
        <div class="or-mt-8-px">
          <ion-chip
            *ngFor="let from of availableShowFromValues"
            class="or-head-xs border-radius-20 or-p-8-px"
            [ngClass]="viewFrom(from) ? 'chip-checked' : 'chip-unchecked'"
            (click)="setViewFromChips(from)">
            {{ viewFromValuesMapper[from] }}
          </ion-chip>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="or-d-flex or-justify-content-center or-w-100-pc">
  <button class="button-secondary-style or-h-48-px or-head-md or-w-96-px or-mr-8-px" (click)="loadSettings()">
    Wyzeruj
  </button>
  <button class="button-primary-style or-h-48-px or-head-md or-w-96-px" (click)="saveSettings()">Zapisz</button>
</div>

<or-loading message="Zapisywanie ustawień..."></or-loading>
