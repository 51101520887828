import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ShareGameDirective, TitileNavbarComponent } from '@ui-components';
import { NgClass } from '@angular/common';
import { FullHeightScrollableContainerDirective, MODAL_TOKEN, ModalProvider } from '@core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { TeamsRoutes } from '../../teams-routes.enum';
import { map, Observable, switchMap, take } from 'rxjs';
import { TeamInviteData } from '../../application/models';
import { ShareTeamModalComponent } from '../share-team-modal/share-team-modal.component';

@Component({
  selector: 'lib-create-team-finish',
  templateUrl: './create-team-finish.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, NgClass, ShareGameDirective, FullHeightScrollableContainerDirective]
})
export class CreateTeamFinishComponent {
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);

  readonly teamData$: Observable<TeamInviteData> = this.activatedRoute.params.pipe(
    map((params: Params) => ({
      teamId: params['id'],
      token: params['token']
    }))
  );

  goToDetails(): void {
    this.teamData$
      .pipe(
        take(1),
        switchMap((teamData: TeamInviteData) =>
          this.router.navigateByUrl(`${AppRoutes.TEAMS}/${teamData.teamId}/${TeamsRoutes.TEAM_DETAILS}`)
        )
      )
      .subscribe();
  }

  invitePlayers(): void {
    this.teamData$
      .pipe(
        take(1),
        switchMap((teamData: TeamInviteData) =>
          this.router.navigateByUrl(`${AppRoutes.TEAMS}/${teamData.teamId}/${teamData.token}/${TeamsRoutes.INVITE}`)
        )
      )
      .subscribe();
  }

  share(): void {
    this.teamData$
      .pipe(
        take(1),
        switchMap((teamData: TeamInviteData) =>
          this.modalProvider.showModal$({
            component: ShareTeamModalComponent,
            componentProps: { teamData: teamData },
            cssClass: 'modal-auto',
            initialBreakpoint: 1,
            breakpoints: [0, 1]
          })
        )
      )
      .subscribe();
  }
}
