<lib-title-navbar
  [showBackArrow]="true"
  title="Ranking - Top 50"
  [showRankQAIcon]="true"
  (onRankQAClicked)="goToRankQA()"></lib-title-navbar>

<div class="or-p-16-px">
  <ng-container *ngFor="let user of topRank$ | async; let i = index; let first = first">
    <div class="or-d-flex or-justify-content-between or-align-items-center or-mb-12-px" [class.or-mt-16-px]="!first">
      <div class="or-d-flex or-align-items-center">
        <div class="or-head-sm or-color-second ary-2 or-mr-8-px">{{ i + 1 }}</div>
        <lib-or-avatar
          [avatarUrl]="user.thumbnail"
          [initials]="user.displayName"
          [avatarSize]="'sm'"
          [rightMargin]="false"
          class="or-mr-8-px"></lib-or-avatar>
        <div class="or-head-sm">{{ user.displayName }}</div>
      </div>
      <div class="or-head-sm">{{ user.points }}</div>
    </div>
    <div class="custom-divider or-w-100-pc"></div>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
