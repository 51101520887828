import { NotificationsService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class MarkAsReadNotificationsCommandHandler {
  private readonly notificationsService: NotificationsService = inject(NotificationsService);

  markAsRead(ids: string[]): Observable<void> {
    return this.notificationsService.markAsRead(ids);
  }
}
