import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe, NgForOf } from '@angular/common';
import { TrophyTypeEnum } from '../../application/enums';
import { userTrophiesMapper } from '../../application/mappers';
import { BottomNavbarComponent, TitileNavbarComponent } from '@ui-components';
import { FullHeightScrollableContainerDirective } from '@core';

@Component({
  selector: 'lib-all-trophies',
  templateUrl: './all-trophies.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgForOf, TitileNavbarComponent, BottomNavbarComponent, FullHeightScrollableContainerDirective]
})
export class AllTrophiesComponent {
  readonly allTrophies: TrophyTypeEnum[] = Object.values(TrophyTypeEnum);
  readonly trophiesMapper = userTrophiesMapper;
}
