import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_CONFIG, EnvConfig } from '@core';
import { Observable } from 'rxjs';
import { RatingDTO } from '../../application/interfaces';

export class RatingService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly env: EnvConfig = inject(ENV_CONFIG);

  rateApplication(rating: RatingDTO): Observable<void> {
    return this.httpClient.post<void>(`${this.env.apiUrl}/rating/app`, rating);
  }
}
