<lib-title-navbar title="Pytania i odpowiedzi"></lib-title-navbar>

<div class="or-p-12-px">
  <div
    (click)="changeVisibility(item)"
    *ngFor="let item of faqContent$ | async"
    class="border-radius-4 card-box-shadow or-bg-white or-head-md or-p-12-px or-mt-12-px">
    <div class="or-d-flex or-align-items-center or-justify-content-between">
      <div class="or-head-md">{{ item.title }}</div>
      <img
        [src]="item.isVisible ? 'assets/icons/chevron-up.svg' : 'assets/icons/chevron-down-primary.svg'"
        class="or-mr-8-px" />
    </div>
    <div *ngIf="item.isVisible" class="or-text-md or-mt-8-px or-color-secondary-2">{{item.content}}</div>
    <div *ngIf="item.isVisible" class="or-mt-8-px or-text-md or-color-secondary-2 or-px-12-px">
      <div *ngFor="let listItem of item.listItems" class="">
        <li>{{ listItem }}</li>
      </div>
    </div>
  </div>
</div>
