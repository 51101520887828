import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HostedGamesRouting } from './hosted-games.routing';
import { HostedGamesService } from './infrastructure/http-service';

@NgModule({
  imports: [RouterModule.forChild(HostedGamesRouting)],
  providers: [HostedGamesService]
})
export class HostedGamesModule {}
