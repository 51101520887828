<lib-home-navbar
  (onAddGameOpened)="goToGameCreator()"
  [isNewNotifications]="!!(isNewNotifications$ | async)"
  [showFiltersIcon]="false"></lib-home-navbar>

<div class="dashboard-top-container"></div>

<div class="negative-margin-80-px z-index-999">
  <lib-dashboard-navbar [canCreateTeam]="canCreateTeam"> </lib-dashboard-navbar>
</div>

<div [fullHeightScrollableContainer]="220">
  <ng-container *ngIf="!isEmptyStateVisible">
    <lib-dashboard-teams
      (isTeamsAvailable)="setIsTeamsAvailable($event)"
      (canCreateTeam)="setCanCreateTeam($event)"></lib-dashboard-teams>

    <lib-dashboard-invitations
      (isInvitationsAvailable)="setIsInvitationsAvailable($event)"
      (invitationAccepted)="invitationAccetped()"></lib-dashboard-invitations>

    <lib-dashboard-games-list (isGameAvailable)="setIsGameAvailable($event)"></lib-dashboard-games-list>
  </ng-container>

  <ng-container *ngIf="isEmptyStateVisible">
    <lib-dashboard-empty-state></lib-dashboard-empty-state>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
