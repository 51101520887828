import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IntroductionRouting } from './introduction.routing';
import { IntroductionService } from './lib/infrastructure';

@NgModule({
  imports: [RouterModule.forChild(IntroductionRouting)],
  providers: [IntroductionService]
})
export class IntroductionModule {}
