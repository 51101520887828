import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BottomNavbarComponent, OrAvatarComponent, TitileNavbarComponent } from '@ui-components';
import { GetTopRankQueryHandler } from '../../application/handlers';
import { TopRankDTO } from '../../application/interfaces';
import { Observable } from 'rxjs';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { RankRoutes } from '../../rank.routes.enum';

@Component({
  selector: 'lib-total-rank',
  templateUrl: './total-rank.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent, BottomNavbarComponent, NgIf, AsyncPipe, NgForOf, OrAvatarComponent],
  providers: [GetTopRankQueryHandler]
})
export class TotalRankComponent {
  private readonly getTopRankQueryHandler: GetTopRankQueryHandler = inject(GetTopRankQueryHandler);
  private readonly router: Router = inject(Router);

  readonly topRank$: Observable<TopRankDTO[]> = this.getTopRankQueryHandler.getUserRank();

  goToRankQA(): void {
    this.router.navigateByUrl(`${AppRoutes.RANK}/${RankRoutes.QA}`);
  }
}
