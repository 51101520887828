import { inject } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { NearbyGamesService } from '../../infrastructure/http-services';
import { FiltersStorage } from '../../infrastructure/storages';
import { FiltersValues } from '../models';
import { NerabyGamesDTO } from '@core';
import { parseFiltersData } from '../utils';

export class GetMapMarkersQueryHandler {
  private nearbyGamesService: NearbyGamesService = inject(NearbyGamesService);
  private filtersStorage: FiltersStorage = inject(FiltersStorage);

  getMapMarkers(): Observable<{ locations: NerabyGamesDTO[] }> {
    return this.filtersStorage.filters$.pipe(
      map((filters: FiltersValues) => {
        const { maxDistanceMeters, longitude, latitude, ...adjustedFilters } = filters;

        return adjustedFilters as FiltersValues;
      }),
      map((filters: FiltersValues) => parseFiltersData(filters)),
      switchMap((filters: string) => this.nearbyGamesService.getMarkers(filters))
    );
  }
}
