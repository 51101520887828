import { inject } from '@angular/core';
import { HostedGamesService } from '../../infrastructure/http-service';
import { map, Observable, take } from 'rxjs';
import { GameDetailsModel, GameDTO, mapGameDTOToGameDetailsUtil } from '@core';

export class GetHostedGameQueryHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);

  getGame(gameId: string): Observable<GameDetailsModel> {
    return this.hostedGamesService.getOneGame(gameId).pipe(
      take(1),
      map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game))
    );
  }
}
