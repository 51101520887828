import { inject } from '@angular/core';
import { CredentialsDTO } from '../models';
import { from, Observable, of, switchMap, tap } from 'rxjs';
import { USER_DATA_TOKEN, UserDataProvider } from '@core';
import { AuthService } from '../../infrastructure/http-services';

export class LoginCommandHandler {
  private readonly authService: AuthService = inject(AuthService);
  private readonly userDataProvider: UserDataProvider = inject(USER_DATA_TOKEN);

  login(credentials: CredentialsDTO): Observable<void> {
    return from(this.authService.login(credentials)).pipe(
      tap(() => this.userDataProvider.setUserData()),
      switchMap(() => of(void 0))
    );
  }
}
