import { Observable, of, switchMap, take, tap } from 'rxjs';
import { inject, Injector, runInInjectionContext } from '@angular/core';
import { IsAuthedService } from '@core';
import { AuthService } from '../../infrastructure/http-services';

export const setReferrerIdUtil = (injector: Injector): Observable<void> => {
  const referrerKey: string = 'referrer';
  const referrerId = localStorage.getItem(referrerKey);

  return runInInjectionContext(injector, () => {
    const authService: AuthService = inject(AuthService);

    const isAuthedService: IsAuthedService = inject(IsAuthedService);

    return isAuthedService.isAuthed$.pipe(
      switchMap((isAuthed: boolean) => {
        if (isAuthed && referrerId)
          return authService.setReferrer(referrerId).pipe(
            take(1),
            tap(() => localStorage.removeItem(referrerKey))
          );

        return of(void 0);
      })
    );
  });
};
