import { GameDetailsModel, GameDTO } from '../interfaces';
import { gameLevelMapper, paymentTypesMapper } from '../mappers';
import { PaymentType } from '../enums';
import { formatDateToString } from './date-to-string.util';
import { capitalizeFirstLetter } from './capitalize-first-letter.util';

export const mapGameDTOToGameDetailsUtil = (game: GameDTO): GameDetailsModel => {
  return {
    gameName: game.name,
    gameId: game.gameId,
    formattedDate: formatDate(game.gameDateStart, game.gameDateEnd),
    gameDateStart: game.gameDateStart,
    duration: getDuration(game.gameDateStart, game.gameDateEnd),
    location: game.location,
    totalSlots: game.totalSlots,
    occupiedSlots: game.occupiedSlots,
    level: gameLevelMapper[game.level],
    priceAmount: +game.priceAmount,
    paymentType: game.paymentTypes?.map((type: PaymentType) => paymentTypesMapper[type]),
    description: game.description ?? '',
    players: game.gamePlayers,
    gamePlayersLeft: game.gamePlayersLeft,
    playersApplications: game.playerApplications,
    invitations: game.invitations,
    invitationsRejected: game.invitationsRejected,
    gameContactPhone: game.gameContactPhone,
    visibility: game?.visibility,
    isRecurring: game?.isRecurring,
    channel: game?.channel,
    isHost: game.isCurrentUserHost,
    locationType: game?.locationType,
    gameParticipationStatus: game.currentUserParticipationStatus,
    host: game.host,
    isPaid: game.isPaid,
    gameInvitationId: game?.gameInvitationId
  };
};

const getDuration = (startDate: string, endDate: string) => {
  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);
  const differenceInMilliseconds: number = end.getTime() - start.getTime();
  return differenceInMilliseconds / (1000 * 60);
};

const formatDate = (startDate: string, endDate: string) => {
  const date = capitalizeFirstLetter(formatDateToString(startDate, 'EEEE, dd.MM.yyyy'));
  const startTime = formatDateToString(startDate, 'HH:mm');
  const endTime = formatDateToString(endDate, 'HH:mm');
  return `${date} | ${startTime} - ${endTime}`;
};
