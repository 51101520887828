import { map, Observable, take } from 'rxjs';
import { inject } from '@angular/core';
import { GamesService } from '../../infrastructure/http-service';
import { GameDetailsModel, GameDTO, mapGameDTOToGameDetailsUtil } from '@core';

export class GameDetailsQueryHandler {
  private readonly gamesService: GamesService = inject(GamesService);

  getGameDetails(gameId: string): Observable<GameDetailsModel> {
    return this.gamesService.getOneGame(gameId).pipe(
      take(1),
      map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game))
    );
  }
}
