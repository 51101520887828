import { RatingService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { RatingDTO } from '../interfaces';
import { Observable } from 'rxjs';

export class RateAppCommandHandler {
  private readonly ratingService: RatingService = inject(RatingService);

  rateApp(rating: RatingDTO): Observable<void> {
    return this.ratingService.rateApplication(rating);
  }
}
