import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { GameDetailsModel, GameDTO, mapGameDTOToGameDetailsUtil } from '@core';
import { FutureGameDTO } from '../interfaces';
import { FutureGamesStorage } from '../../infrastructure/storage';

export class GetFutureGamesQueryHandler {
  private readonly futureGamesStorage: FutureGamesStorage = inject(FutureGamesStorage);

  getApproved(): Observable<GameDetailsModel[]> {
    return this.futureGamesStorage.games$.pipe(
      map((games: FutureGameDTO) => games.approved.map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game)))
    );
  }

  getWaiting(): Observable<GameDetailsModel[]> {
    return this.futureGamesStorage.games$.pipe(
      map((games: FutureGameDTO) => games.waiting.map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game)))
    );
  }

  getInvitations(): Observable<GameDetailsModel[]> {
    return this.futureGamesStorage.games$.pipe(
      map((games: FutureGameDTO) => games.invitations.map((game: GameDTO) => mapGameDTOToGameDetailsUtil(game)))
    );
  }
}
