import { HostedGamesService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class RemovePlayerCommandHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);

  remove(gameId: string, playerId: string): Observable<void> {
    return this.hostedGamesService.removePlayer(gameId, playerId);
  }
}
