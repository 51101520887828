import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatService } from '../../infrastructure/http-service';
import { ChannelDetailsDTO } from '../interfaces';

export class ChannelDetailsQueryHandler {
  private readonly chatService: ChatService = inject(ChatService);

  details(channelId: string): Observable<ChannelDetailsDTO> {
    return this.chatService.channelDetails(channelId);
  }
}
