<ng-container *ngIf="invitations$ | async as invitations">
  <ng-container *ngIf="invitations?.length">
    <div
      (click)="changeInvitationsVisibility()"
      class="or-d-flex or-justify-content-between or-align-items-center or-px-24-px or-mt-20-px">
      <div class="or-head-md">Zaproszenia</div>
      <img
        [src]="
          isInvitationsExpanded
            ? 'assets/icons/chevron-up.svg'
            : 'assets/icons/chevron-down-primary.svg'
        " />
    </div>
    <ng-container *ngIf="isInvitationsExpanded">
      <div
        (click)="goToDetails(game.gameId)"
        *ngFor="let game of invitations"
        class="or-mb-12-px or-mt-12-px invite-card-gradient or-mx-16-px or-p-12-px border-radius-8">
        <div class="or-text-sm or-color-secondary-4">{{game.host?.displayName}} zaprasza Cię do gierki!</div>
        <div class="or-head-md or-color-white or-my-4-px">{{game.gameName}}</div>
        <div class="or-text-md or-color-white">{{game.formattedDate}}</div>
        <div (click)="stopEventPropagation($event)" class="or-mt-16-px">
          <button
            class="button-primary-style or-h-24-px or-head-xs or-w-112-px or-mr-12-px"
            [sendApplication]="game.gameId"
            (applicationSent)="refresh()"
            (onBlockMulticlick)="blockMulticlick()"
            [disabled]="isDisabled">
            Gram!
          </button>
          <button
            (click)="decline(game.gameInvitationId)"
            class="button-secondary-style or-h-24-px or-head-xs or-w-112-px">
            Nie gram
          </button>
        </div>
      </div>
    </ng-container></ng-container
  >
</ng-container>
