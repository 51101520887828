import { Routes } from '@angular/router';
import { FindGamesHomeComponent, GamesListComponent, LocationDetailsComponent, MapComponent } from './components';
import { blockWebVersionGuard } from '@core';
import { isAuthedGuard } from '@auth';
import { FindGamesRoutes } from './find-games.routes.enum';

export const FindGamesRouting: Routes = [
  {
    path: '',
    component: FindGamesHomeComponent,
    children: [
      {
        path: '',
        component: GamesListComponent
      },
      {
        path: FindGamesRoutes.MAP,
        component: MapComponent
      }
    ]
  },
  {
    path: `${FindGamesRoutes.LOCATION}/:id`,
    component: LocationDetailsComponent,
    canActivate: [blockWebVersionGuard, isAuthedGuard]
  }
];
