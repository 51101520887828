import { NgModule } from '@angular/core';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { MarkAsReadNotificationsCommandHandler, NotificationsListQueryHandler } from './application/handlers';
import { CustomNotificationService, NotificationsAlertsService } from './infrastructure';

@NgModule({
  imports: [NotificationsRoutingModule],
  providers: [
    NotificationsListQueryHandler,
    MarkAsReadNotificationsCommandHandler,
    NotificationsAlertsService,
    CustomNotificationService
  ]
})
export class NotificationsModule {}
