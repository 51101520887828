import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { GameApplicationsService, GamesRoutes, SendApplicationDirective } from '@games';
import { RedirectTypeEnum } from '@ui-components';
import { Observable, switchMap, tap } from 'rxjs';
import { GameDetailsModel, LocalRefreshService, MODAL_TOKEN, ModalProvider } from '@core';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DeclineInvitationCommandHandler, InvitationsService } from '@invitations';
import {
  GetFutureGamesQueryHandler,
  LoadFutureGamesCommandHandler,
  SendApplicationCommandHandler
} from '../../../../../games/src/lib/application/handlers';
import { AppRoutes } from 'src/app/app-routes.enum';
import { Router } from '@angular/router';
import { AdMob, BannerAdOptions, BannerAdPosition } from '@capacitor-community/admob';
import { BannerAdSize } from '@capacitor-community/admob/dist/esm/banner/banner-ad-size.enum';

@Component({
  selector: 'lib-dashboard-invitations',
  templateUrl: './dashboard-invitations.component.html',
  styleUrls: ['./dashboard-invitations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf, IonicModule, SendApplicationDirective, NgTemplateOutlet],
  providers: [
    GetFutureGamesQueryHandler,
    SendApplicationCommandHandler,
    GameApplicationsService,
    LocalRefreshService,
    DeclineInvitationCommandHandler,
    InvitationsService
  ]
})
export class DashboardInvitationsComponent {
  private readonly modalProvider: ModalProvider = inject(MODAL_TOKEN);
  private readonly getFutureGamesQueryHandler: GetFutureGamesQueryHandler = inject(GetFutureGamesQueryHandler);
  private readonly declineInvitationCommandHandler: DeclineInvitationCommandHandler = inject(
    DeclineInvitationCommandHandler
  );
  private readonly loadFutureGamesCommandHandler: LoadFutureGamesCommandHandler = inject(LoadFutureGamesCommandHandler);
  private readonly router: Router = inject(Router);

  @Output() isInvitationsAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() invitationAccepted: EventEmitter<void> = new EventEmitter<void>();

  public isInvitationsExpanded: boolean = true;
  public gameRedirectType: RedirectTypeEnum = RedirectTypeEnum.GAME;
  public isDisabled: boolean = false;

  constructor() {
    // this.testAds();
  }

  async testAds() {
    const options: BannerAdOptions = {
      adId: 'ca-app-pub-3940256099942544/6300978111', // Wstaw swój Ad Unit ID
      adSize: BannerAdSize.LARGE_BANNER,
      position: BannerAdPosition.CENTER,
      margin: 0
    };

    await AdMob.showBanner(options);
  }

  readonly invitations$: Observable<GameDetailsModel[]> = this.modalProvider.showLoading$().pipe(
    switchMap(() => this.getFutureGamesQueryHandler.getInvitations()),
    tap((result: GameDetailsModel[]) => this.isInvitationsAvailable.emit(!!result?.length)),
    tap(() => this.modalProvider.dismissLoading$())
  );

  goToDetails(gameId: string): void {
    this.router.navigate([`${AppRoutes.GAMES}/${GamesRoutes.DETAILS}/${gameId}`]);
  }

  refresh(): void {
    this.loadFutureGamesCommandHandler.load().subscribe();
    this.invitationAccepted.emit();
  }

  stopEventPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  changeInvitationsVisibility(): void {
    this.isInvitationsExpanded = !this.isInvitationsExpanded;
  }

  decline(invitaionId: string): void {
    this.declineInvitationCommandHandler.decline(invitaionId).subscribe(() => this.refresh());
  }

  blockMulticlick() {
    this.isDisabled = true;
  }
}
