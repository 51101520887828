import { from, map, Observable, switchMap, take } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../../infrastructure/http-services';

export class RemoveAccountCommandHandler {
  private readonly authService: AuthService = inject(AuthService);

  removeAccount(password: string): Observable<void> {
    return from(this.authService.reauthenticate(password)).pipe(
      take(1),
      switchMap(() => this.authService.removeAccount()),
      map(() => void 0)
    );
  }
}
