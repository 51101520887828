<lib-title-navbar title="Twoje gierki"></lib-title-navbar>
<div class="top-container"></div>

<div class="margin-top--24-px">
  <lib-games-menu
    (goToFutureEvent)="goToFutureGames()"
    (goToPastEvent)="goToPastGames()"
    [isActivePast]="true"></lib-games-menu>
</div>

<div
  [fullHeightScrollableContainer]="190"
  [ngClass]="{'or-align-items-center or-d-flex or-justify-content-center':showPlaceholder$ | async}">
  <div *ngIf="games$ | async as groupedGames">
    <div *ngFor="let date of groupedGames.keys()">
      <div class="or-ml-32-px or-head-sm or-mb-8-px or-mt-24-px or-color-secondary-2">{{ date }}</div>
      <div *ngFor="let game of groupedGames.get(date)" class="or-mb-12-px">
        <lib-game-card-v2
          [game]="game"
          [redirectType]="redirectType"
          [showHostDetails]="true"
          [showBottomDetails]="false"></lib-game-card-v2>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isPageLoaded$ | async">
    <div *ngIf="showPlaceholder$ | async" class="or-head-sm">Nie grałeś w żadnych gierkach.</div>
  </ng-container>
</div>

<lib-bottom-navbar></lib-bottom-navbar>
