import { ChangeDetectionStrategy, Component, inject, Injector, ViewEncapsulation } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AppRoutes } from 'src/app/app-routes.enum';
import { AuthRoutes } from '../../auth-routes.enum';
import { TitileNavbarComponent } from '@ui-components';
import { AppVersion, BuildVersion, PLATFORM_TOKEN, PlatformProvider } from '@core';
import { NgIf } from '@angular/common';
import { take } from 'rxjs';
import { FindGamesRoutes } from '../../../../../find-games/src/lib/find-games.routes.enum';
import { AuthWithProviderCommandHandler } from '../../application/handlers';
import { redirectAfterLoginUtil } from '../../application/utils';

@Component({
  templateUrl: './auth-home.page.html',
  styleUrls: ['./auth-home.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TitileNavbarComponent, RouterLink, NgIf]
})
export class AuthHomePage {
  public appVersion: string = AppVersion;
  public buildVersion: string = BuildVersion;
  private readonly router: Router = inject(Router);
  private readonly platformProvider: PlatformProvider = inject(PLATFORM_TOKEN);
  private readonly authWithProviderCommandHandler: AuthWithProviderCommandHandler =
    inject(AuthWithProviderCommandHandler);
  private readonly injector: Injector = inject(Injector);

  readonly isAndroid: boolean = this.platformProvider.isAndroid;

  goToLogin(): void {
    this.router.navigate([`/${AppRoutes.AUTH}/${AuthRoutes.LOGIN}`]);
  }

  goToRegister(): void {
    this.router.navigate([`/${AppRoutes.AUTH}/${AuthRoutes.REGISTER}`]);
  }

  goToMap(): void {
    this.router.navigate([`${AppRoutes.FIND_GAMES}/${FindGamesRoutes.MAP}`]);
  }

  async googleAuth(): Promise<void> {
    this.authWithProviderCommandHandler
      .authWithGoogle()
      .pipe(take(1))
      .subscribe(() => redirectAfterLoginUtil(this.injector));
  }

  async appleAuth(): Promise<void> {
    this.authWithProviderCommandHandler
      .authWithApple()
      .pipe(take(1))
      .subscribe(() => redirectAfterLoginUtil(this.injector));
  }

  getTermsOfService(): void {
    window.open(
      'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/docs%2FOrlikfy%20Regulamin.pdf?alt=media&token=337ce6fa-1b40-4ed9-a89d-4a917d332e91'
    );
  }

  getPolicyPrivacy(): void {
    window.open(
      'https://firebasestorage.googleapis.com/v0/b/orlikfy-infra-prod.appspot.com/o/docs%2FOrlikfy%20Polityka%20Prywatnosci.pdf?alt=media&token=ad93ba59-a2ef-445e-b4d2-5d75da613b9f'
    );
  }
}
