import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, DatePipe, JsonPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Observable } from 'rxjs';
import { CopyService, CopyValueToClipboardDirective } from '@core';
import { UsersListDetailsDTO } from '../../../application/models';
import { GetAdminUsersListQueryHandler } from '../../../application/handlers';
import { ChatService, CreateSingleChatCommandHandler } from '@messages';

@Component({
  selector: 'lib-users-list',
  templateUrl: './users-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, JsonPipe, NgIf, NgForOf, DatePipe, CopyValueToClipboardDirective, NgTemplateOutlet],
  providers: [CopyService, GetAdminUsersListQueryHandler, CreateSingleChatCommandHandler, ChatService]
})
export class UsersListComponent {
  private readonly getAdminUsersListQueryHandler: GetAdminUsersListQueryHandler = inject(GetAdminUsersListQueryHandler);
  private readonly createSingleChatCommandHandler: CreateSingleChatCommandHandler =
    inject(CreateSingleChatCommandHandler);

  readonly usersList$: Observable<UsersListDetailsDTO[]> = this.getAdminUsersListQueryHandler.getList();

  sendMessage(userId: string, event: Event): void {
    event.stopImmediatePropagation();
    this.createSingleChatCommandHandler.create(userId).subscribe();
  }
}
