import { map, Observable } from 'rxjs';
import { HostedGamesService } from '../../infrastructure/http-service';
import { inject } from '@angular/core';
import { CreateGameDTO, CreateGameResponseDTO, CreateGameResponseViewModel } from '../interfaces';
import { DateFormatPipe } from '@ui-components';
import { mapToCreateGameViewModelUtil } from './utils/map-to-create-game-view-model.util';

export class UpdateGameCommandHandler {
  private readonly hostedGamesService: HostedGamesService = inject(HostedGamesService);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);

  updateGame(gameId: string, game: Partial<CreateGameDTO>): Observable<CreateGameResponseViewModel> {
    return this.hostedGamesService
      .updateGame(gameId, game)
      .pipe(map((response: CreateGameResponseDTO) => mapToCreateGameViewModelUtil(response, this.dateFormatPipe)));
  }
}
