import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TitileNavbarComponent } from '@ui-components';

@Component({
  selector: 'lib-rank-qa',
  templateUrl: './rank-qa.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitileNavbarComponent]
})
export class RankQaComponent {}
