import { NotificationsService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class MarkNotificationAsClickedCommandHandler {
  private readonly notificationsService: NotificationsService = inject(NotificationsService);

  markasClicked(notificationId: string): Observable<void> {
    return this.notificationsService.markAsClicked(notificationId);
  }
}
