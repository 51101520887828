<lib-title-navbar title="Dodaj graczy"></lib-title-navbar>

<ng-container *ngIf="candidates$ | async as players">
  <div class="or-px-12-px" [fullHeightScrollableContainer]="145">
    <ng-container *ngIf="!players?.length">
      <ng-container *ngTemplateOutlet="invitationsUnavailable"></ng-container>
    </ng-container>

    <div
      *ngIf="players?.length"
      class="or-d-flex or-justify-content-between or-mb-16-px or-mt-24-px or-head-sm or-align-items-center or-mx-8-px">
      <div class="or-color-secondary-2">Lista dawnych graczy</div>
      <div class="or-d-flex or-justify-content-between or-align-items-center">
        <div>Zaznacz wszystkich</div>
        <ion-checkbox [checked]="false" (ionChange)="selectAll($event)" class="or-ml-8-px"></ion-checkbox>
      </div>
    </div>

    <div *ngFor="let player of players; let i = index">
      <div
        class="or-bg-white or-d-flex or-justify-content-between or-p-8-px or-mb-4-px player-box border-radius-8"
        [ngClass]="
          isSelected(player.userId)
            ? 'border-primary or-bg-selected'
            : 'player-box or-bg-white'
        ">
        <div class="or-d-flex or-align-items-center or-w-100-pc">
          <ion-checkbox
            [checked]="isSelected(player.userId)"
            (ionChange)="selectPlayer(player.userId)"
            class="or-mr-8-px"></ion-checkbox>
          <lib-or-avatar
            [avatarUrl]="player.thumbnail"
            [initials]="player.displayName"
            [avatarSize]="'sm'"></lib-or-avatar>

          <div class="or-head-sm or-d-flex or-align-items-center">
            {{ i + 1 }}.&nbsp;
            <span class="or-truncate-at-line-1">{{ player.displayName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="players?.length" class="or-mx-12-px or-mb-12-px">
    <button
      (click)="inviteUsers()"
      class="button-primary-style or-h-48-px or-head-md or-w-100-pc"
      [class.disabled-button]="!(selectedUsers$ | async)?.length">
      Dodaj graczy
    </button>
  </div>

  <ng-template #invitationsUnavailable>
    <div class="or-mt-32-px or-text-align-center or-text-md or-h-100-pc or-align-content-center">
      <div>Brak graczy z poprzednich gierek.</div>
      <div class="or-mt-12-px">Dodaj graczy do grupki za pomocą linku.</div>

      <button
        class="button-primary-style or-h-48-px or-w-90-pc or-head-md or-mb-12-px or-mt-32-px"
        (click)="inviteViaLink()">
        <div class="or-align-items-center or-d-flex or-justify-content-center">
          <img src="assets/icons/share-icon.svg" class="or-mr-12-px" />
          <span class="or-mr-12-px">Zaproś za pomocą linku</span>
        </div>
      </button>
    </div></ng-template
  >
</ng-container>
