import { RankService } from '../../infrastructure';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TopRankDTO } from '../interfaces';

export class GetTopRankQueryHandler {
  private readonly rankService: RankService = inject(RankService);

  getUserRank(): Observable<TopRankDTO[]> {
    return this.rankService.getTop50();
  }
}
